/* eslint-disable no-underscore-dangle */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useRecoilValue } from "recoil";
import { useLocation } from "wouter";
import { Flex } from "../utils/HelperStyles";
import ManageLairSideBar from "../components/ManageLair/ManageLairSideBar";
import lairAtom from "../atoms/LairAtom";
import LairSettings from "../components/ManageLair/LairSettings";
import PendingPost from "../components/ManageLair/PendingPost";
import { getLairPendingPost } from "../api/Post";
import userInfoAtom from "../atoms/UserInfoAtom";
import { PostType } from "../utils/Types";

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colors.background};
  height: 100vh;
  overflow: scroll;
  padding-top: 50px;
  display: grid;
  grid-template-columns: 221px auto;
`;

const Container = styled.div`
  position: relative;
  ${Flex};
  flex-direction: column;
`;

const PENDING_POST = 2;
const LAIR_SETTINGS = 5;

export default function ManageLairScreen(): JSX.Element {
  const lair = useRecoilValue(lairAtom);
  const userInfo = useRecoilValue(userInfoAtom);
  const [content, setContent] = useState(2);
  const [pendingPost, setPendingPost] = useState<PostType[]>([]);
  const [, setLocation] = useLocation();

  async function loadPendingPost() {
    if (lair) {
      const postResponse: PostType[] | null = await getLairPendingPost(
        lair._id
      );
      if (postResponse) setPendingPost(postResponse);
    }
  }

  useEffect(() => {
    (async () => {
      await loadPendingPost();
    })();

    if (lair && userInfo) {
      if (lair.admin !== userInfo.uid) {
        setLocation("/home");
      }
    }
  }, [lair, userInfo]);

  return (
    <Wrapper>
      <ManageLairSideBar
        setContent={(value: number) => setContent(value)}
        content={content}
        count={{ pendingPost: pendingPost.length }}
      />
      <Container>
        {content === PENDING_POST && (
          <PendingPost posts={pendingPost} onChange={loadPendingPost} />
        )}
        {content === LAIR_SETTINGS && <LairSettings />}
      </Container>
    </Wrapper>
  );
}
