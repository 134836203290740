import { User } from "../utils/Types";
import { SERVER_URL } from "../utils/Url";

interface ICreateUser {
  uid: string;
  name: string;
  username: string;
  email: string;
  phone: string;
}

export async function getUserByUid(userUid: string): Promise<User | null> {
  try {
    const response: Response = await fetch(`${SERVER_URL}/user/${userUid}`);

    if (response.status === 200) {
      const responseObject: User = await response.json();
      return responseObject;
    }

    return null;
  } catch (err) {
    localStorage.clear();
    window.location.reload();
    return null;
  }
}

export async function getUserByUsername(
  username: string
): Promise<User | null> {
  const response: Response = await fetch(
    `${SERVER_URL}/user/username/${username}`
  );

  if (response.status === 200) {
    const responseObject: User = await response.json();
    return responseObject;
  }

  return null;
}

export async function getUsers(users: string[]): Promise<User[] | null> {
  const response = await fetch(`${SERVER_URL}/user/array`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ users }),
  });

  const responseObject: User[] = await response.json();

  return responseObject;
}

export async function updateUser(userInfo: any): Promise<boolean> {
  const response: Response = await fetch(`${SERVER_URL}/user/${userInfo.uid}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userInfo),
  });

  if (response.status === 200) return true;
  return false;
}

export async function createUser(userInfo: ICreateUser): Promise<boolean> {
  const response: Response = await fetch(`${SERVER_URL}/user`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userInfo),
  });

  console.log(response);

  if (response.status === 200) return true;
  return false;
}

export async function disconnectFromUser(
  uidUser: string,
  uidUserConnection: string
): Promise<boolean> {
  const response = await fetch(
    `${SERVER_URL}/user/disconnect/${uidUser}/${uidUserConnection}`
  );

  if (response.status === 200) {
    return true;
  }

  return false;
}

export async function connectionUser(
  uidUser: string,
  uidUserConnection: string
): Promise<boolean> {
  const response = await fetch(
    `${SERVER_URL}/user/connect/${uidUser}/${uidUserConnection}`
  );
  if (response.status === 200) {
    return true;
  }
  return false;
}

export async function searchUsers(search: string): Promise<User[] | null> {
  const reponse = await fetch(`${SERVER_URL}/user/search/${search}`);

  if (reponse.status === 200) {
    const responseObject = await reponse.json();
    return responseObject;
  }
  return null;
}
