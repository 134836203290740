import styled from "@emotion/styled";
import React from "react";
import Logo from "../../assets/img/logo-light.svg";

const Wrapper = styled.div`
  /* height: 150px; */
  width: 400px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  @media screen and (max-width: 600px) {
    width: 42%;
  }
`;

const TitleContainer = styled.div`
  color: #2f2f2f;
  font-size: 24px;
`;
const SubTitleContainer = styled.div`
  color: #818a91;
  font-size: 16px;
`;

interface IAuthHeader {
  title: string;
  subtitle: string;
}

export default function AuthHeader(props: IAuthHeader): JSX.Element {
  const { subtitle, title } = props;

  return (
    <Wrapper>
      <TitleContainer>{title}</TitleContainer>
      <SubTitleContainer>{subtitle}</SubTitleContainer>
    </Wrapper>
  );
}
