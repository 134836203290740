/* eslint-disable no-underscore-dangle */
import styled from "@emotion/styled";
import React, { useState } from "react";
import { useRecoilState } from "recoil";
import { useLocation } from "wouter";
import { disconnectFromUser, getUserByUid } from "../api/User";
import UnfriendIcon from "../assets/icon/unfriend.svg";
import userInfoAtom from "../atoms/UserInfoAtom";
import Button from "./StyledButton";
import useClickOutside from "../hooks/useClickOutside";
import { User } from "../utils/Types";
import { BUCKET_URL } from "../utils/Url";
import IconButton from "./IconButton";
import { Flex } from "../utils/HelperStyles";
import lairAtom from "../atoms/LairAtom";
import { getLairBytId, removeUserFromLair } from "../api/Lair";
import getImageUrl from "../utils/GetImageURL";

const Card = styled.div`
  ${Flex};
  justify-content: space-between;
  flex-direction: row;
  width: 423px;
  height: 110px;
  border: 1px solid;
  border-color: ${(props: any) =>
    props.color === "secondary"
      ? props.theme.colors.border
      : props.theme.colors.card.border};
  border-radius: 8px;
  margin: 10px 10px;
`;

const Profile = styled.div`
  cursor: pointer;
  ${Flex};
  flex-direction: row;
`;

const Image = styled.img`
  width: 72px;
  height: 72px;
  margin: 0px 15px 0px 25px;
  border-radius: 72px;
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
`;

const Name = styled.span`
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.18px;
  color: ${(props) => props.theme.colors.text};
  margin-bottom: 2px;
`;

const Username = styled.div`
  letter-spacing: 0.15px;
  color: #818a91;
  font-size: 15px;
`;

const Right = styled.div`
  position: relative;
  margin: 0px 26px;
`;

const Modal = styled.div`
  margin-top: 5px;
  background-color: ${(props) => props.theme.colors.background_aux};
  position: absolute;
  width: 200px;
  box-shadow: 0px 5px 15px #00000033;
  border: 1px solid;
  border-color: ${(props) => props.theme.colors.border};
  border-radius: 4px;
  right: 0;
  z-index: 10;
`;

export interface Props {
  connection: User;
  profileUserUid: string;
  color?: string;
  type?: string;
}

export default function UserCard(props: Props): JSX.Element {
  const { connection, profileUserUid, color, type } = props;
  const [modal, showModal] = useState(false);
  const [lair, setLair] = useRecoilState<any>(lairAtom);
  const [, setLocation] = useLocation();

  const [userInfo, setUserInfo] = useRecoilState(userInfoAtom);

  const dom = useClickOutside(() => {
    showModal(false);
  });

  async function onDisconnectClicked(userUid: string) {
    const success = await disconnectFromUser(profileUserUid, userUid);

    if (success) {
      showModal(false);
      const userInfoFromAPI = await getUserByUid(profileUserUid);
      setUserInfo(userInfoFromAPI);
    }
  }

  async function onRemoveClicked(userUid: string) {
    const success = await removeUserFromLair(lair._id, userUid);

    if (success) {
      showModal(false);
      const lairFromAPI = await getLairBytId(lair._id);
      setLair(lairFromAPI);
    }
  }

  function selectModal() {
    if (!userInfo || !lair || userInfo.uid === connection.uid) return null;
    if (type === "user" && userInfo.uid === profileUserUid) {
      return (
        <Modal>
          <IconButton
            iconSVG={UnfriendIcon}
            red
            onClick={async () => {
              await onDisconnectClicked(connection.uid);
            }}
          >
            Disconnect
          </IconButton>
        </Modal>
      );
    }
    if (type === "lair" && lair.admin === userInfo?.uid)
      return (
        <Modal>
          <IconButton
            iconSVG={UnfriendIcon}
            red
            onClick={async () => {
              await onRemoveClicked(connection.uid);
            }}
          >
            Remove
          </IconButton>
        </Modal>
      );

    return null;
  }

  return (
    <Card color={color}>
      <Profile
        onClick={() => {
          setLocation(`/sl/${connection.username}`);
        }}
      >
        <Image src={getImageUrl(connection.image)} />
        <Text>
          <Name>{connection.name}</Name>
          <Username>{connection.username}</Username>
        </Text>
      </Profile>
      <Right ref={dom}>
        <Button
          transparent
          style={{ width: "100px", height: "36px" }}
          onClick={() => {
            showModal(!modal);
          }}
        >
          Connected
        </Button>
        {modal && selectModal()}
      </Right>
    </Card>
  );
}

UserCard.defaultProps = {
  color: "secondary",
  type: "user",
};
