/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import MessageIcon from "../../assets/icon/header/message.svg";
import SearchIcon from "../../assets/icon/search.svg";
import StyledInput from "../StyledInput";
import MessagesCard from "./MessagesCard";
import { Flex } from "../../utils/HelperStyles";

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.header};
  width: 100%;
  ${Flex};
  flex-direction: column;
  border-right: solid 1px ${(props) => props.theme.colors.card.border};
`;

const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 22px 40px;
  width: 100%;
`;

const Title = styled.span`
  color: ${(props) => props.theme.colors.text};
  letter-spacing: 0.18px;
  font-size: 18px;
  font-weight: 600;
`;

const Icons = styled.div``;

const Icon = styled.img`
  width: 20px;
  height: 20px;
`;

export default function MessagesSideBar(): JSX.Element {
  return (
    <Container>
      <Heading>
        <Title>Messages</Title>
        <Icons>
          <Icon
            src={MessageIcon}
            css={css`
              margin-right: 20px;
            `}
          />
          <Icon src={MessageIcon} />
        </Icons>
      </Heading>
      <StyledInput
        style={{ width: "340px", height: "40px", marginBottom: "10px" }}
        placeholder="Search Messages"
        IconSrc={SearchIcon}
        color="secondary"
      />

      <MessagesCard />
      <MessagesCard />
      <MessagesCard active />
      <MessagesCard />
    </Container>
  );
}
