import React, { useState } from "react";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";

import Button from "../StyledButton";
import { Container, Subtext, Text, Title } from "./EditProfile";
import SwitchSetting from "./SwitchSetting";
import { Flex } from "../../utils/HelperStyles";

const Line = styled.div`
  ${Flex};
  justify-content: space-between;
  margin: 20px 43px;
`;

const Description = styled.span`
  font: normal normal medium 15px/17px SF Pro Display;
  font-size: 15px;
  font-weight: medium;
  letter-spacing: 0.15px;
  color: ${(props) => props.theme.colors.text};
`;

export default function Privacy(): JSX.Element {
  const [toggle, setToggle] = useState(false);
  const theme = useTheme();
  return (
    <Container>
      <Title>Privacy Settings</Title>
      <Subtext style={{ marginBottom: "28px" }}>
        Sed ut perspiciatis unde omnis iste natus error sit voluptatem
        accusantium doloremque.
      </Subtext>

      <Text>Account Privacy</Text>
      <Subtext>
        When your account is private, only your friends and followers can see
        your posts on Social Lair.
      </Subtext>

      <SwitchSetting
        style={{ borderBottom: "1px solid", borderColor: theme.colors.border }}
        toggle={toggle}
        setToggle={(res: boolean) => {
          setToggle(res);
        }}
      >
        Make Account Private
      </SwitchSetting>

      <Text style={{ marginTop: "28px" }}>How People Find and Contact You</Text>
      <Subtext>
        Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut
        fugit.
      </Subtext>

      <Line style={{ marginTop: "21px", marginBottom: "15px" }}>
        <Description>Who can send you friend requests?</Description>
        <span>teste</span>
      </Line>

      <Line style={{ marginTop: "15px" }}>
        <Description>Who can send you messages?</Description>
        <span>teste</span>
      </Line>

      <Button
        inverted
        style={{
          margin: "0px 43px 58px 0px",
          alignSelf: "flex-end",
          width: "140px",
        }}
      >
        Save
      </Button>
    </Container>
  );
}
