import React from "react";
import styled from "@emotion/styled";
import NotificationImg from "../../assets/img/notification.svg";
import { Flex } from "../../utils/HelperStyles";

const Container = styled.div`
  z-index: 10;
  position: absolute;
  top: 36px;
  right: -170px;
  width: 380px;
  height: 556px;
  background-color: ${(props) => props.theme.colors.background_aux};
  box-shadow: 0px 3px 8px #0000001f;
  border: 1px solid;
  border-color: ${(props) => props.theme.colors.border};
  border-radius: 4px;
  ${Flex};
  justify-content: center;
  flex-direction: column;
  @media screen and (max-height: 800px) {
    height: 530px;
  }
`;

const NoImg = styled.img`
  width: 61px;
  height: 75px;
`;

const NoTitle = styled.span`
  letter-spacing: 0.2px;
  color: ${(props) => props.theme.colors.text};
  font-size: 20px;
  font-weight: 600;
  margin-top: 10px;
`;

const NoSubtitle = styled.span`
  letter-spacing: 0.16px;
  color: #818a91;
  font-size: 16px;
  margin-top: 8px;
  text-align: center;
`;

const NoButton = styled.span`
  letter-spacing: 0.16px;
  color: #0075bb;
  font-size: 16px;
  margin-top: 32px;
  cursor: pointer;
`;

export default function NotificationPopover(): JSX.Element {
  return (
    <Container>
      <NoImg src={NotificationImg} />
      <NoTitle>No notifications yet</NoTitle>
      <NoSubtitle>
        Stay tuned! Notifications about your activity will show up here.
      </NoSubtitle>
      <NoButton>Notification Settings</NoButton>
    </Container>
  );
}
