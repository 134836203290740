import styled from "@emotion/styled";
import React from "react";
import getImageUrl from "../../utils/GetImageURL";

const Wrapper = styled.div`
  border-top: 1px solid;
  border-color: ${(props) => props.theme.colors.border};
  padding: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  :hover {
    background-color: #eceeef;
  }
`;

const ImageContainer = styled.img`
  border-radius: 100%;
  height: 50px;
`;

const TextContainer = styled.div`
  font-weight: 600;
  margin-left: 20px;
`;

interface IResultUserDiv {
  username: string;
  name: string;
  onClick: () => void;
  image: string;
}

export default function ResultUserDiv(data: IResultUserDiv): JSX.Element {
  const { name, onClick, username, image } = data;

  return (
    <Wrapper onClick={onClick} id={username}>
      <ImageContainer src={getImageUrl(image)} alt="profile-pic" />
      <TextContainer>{name}</TextContainer>
    </Wrapper>
  );
}
