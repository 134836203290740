export default function dateFromObjectId(objectId: string): string {
  const date = new Date(parseInt(objectId.substring(0, 8), 16) * 1000);
  const infoTime: { time: number; prefix: string } = {
    time: Math.abs(new Date().getTime() - date.getTime()) / (1000 * 60),
    prefix: "m",
  };
  if (infoTime.time > 60) {
    infoTime.time /= 60;
    infoTime.prefix = "h";
    if (infoTime.time > 24) {
      infoTime.time /= 24;
      infoTime.prefix = "d";
    }
  }
  return `${infoTime.time.toFixed()}${infoTime.prefix} ago`;
}
