import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useLocation } from "wouter";
import { useRecoilValue } from "recoil";
import { useTheme } from "@emotion/react";
import ProfilePopover from "./ProfilePopover";
import NotificationPopover from "./NotificationPopover";
import StyledInput from "../StyledInput";
import HomeIcon from "../../assets/icon/header/home.svg";
import MoreIcon from "../../assets/icon/more.svg";
import MessageIcon from "../../assets/icon/header/message.svg";
import NotificationIcon from "../../assets/icon/header/notification.svg";
import SearchIcon from "../../assets/icon/search.svg";
import { IconType, User } from "../../utils/Types";
import { BUCKET_URL } from "../../utils/Url";
import useDebounce from "../../hooks/useDebounce";
import ResultSearch from "./ResultSearch";
import useClickOutside from "../../hooks/useClickOutside";
import { searchUsers } from "../../api/User";
import userInfoAtom from "../../atoms/UserInfoAtom";
import { Flex } from "../../utils/HelperStyles";
import ProfilePlaceholder from "../../assets/img/profile-placeholder.png";
import getImageUrl from "../../utils/GetImageURL";
import LogoLight from "../../assets/img/logo-light.svg";

const Container = styled.div`
  display: grid;
  grid-template-columns: 220px auto 1fr;
  height: 55px;
  border-bottom: 1px solid;
  border-color: ${(props) => props.theme.colors.border};
  position: fixed;
  z-index: 99;
  width: 100vw;
`;

const LogoDiv = styled.div`
  ${Flex};
  background-color: ${(props) => props.theme.colors.header};
  justify-content: center;
  cursor: pointer;
  width: 220px;
`;

const SearchDiv = styled.div`
  ${Flex};
  background-color: ${(props) => props.theme.colors.header};
  border-left: 1px solid;
  border-color: ${(props) => props.theme.colors.border};
  position: relative;
`;

const UserDiv = styled.div`
  ${Flex};
  background-color: ${(props) => props.theme.colors.header};
  justify-content: flex-end;
  padding-right: 61px;
`;

const IconDiv = styled.div`
  position: relative;
`;

const Icon = styled.div`
  position: relative;
  mask: url(${(props: IconType) => props.iconSVG}) no-repeat center;
  width: 24px;
  height: 24px;
  background-color: #b1bbc5;

  &:hover {
    background-color: #0075bb;
  }
  margin: 0px 20px;
  cursor: pointer;
`;

const ProfileDiv = styled.div`
  ${Flex};
  position: relative;
  &:hover {
    color: #0075bb;
  }
`;

const ProfileImage = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 32px;
  margin: 0px 7px 0px 20px;
  cursor: pointer;
`;

const ProfileName = styled.span`
  font-size: 17px;
  font-weight: 600;
  letter-spacing: 0px;
  cursor: pointer;
`;

export default function Header(): JSX.Element | null {
  const [, setLocation] = useLocation();
  const [showProfilePopover, setProfilePopover] = useState(false);
  const [showNotificationPopover, setNotificationPopover] = useState(false);
  const userInfo = useRecoilValue(userInfoAtom);
  const [showResultSearch, setResultSearch] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [results, setResults] = useState<User[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const theme = useTheme();

  const domSearch = useClickOutside(() => {
    setResultSearch(false);
  });

  const domNotifications = useClickOutside(() => {
    setNotificationPopover(false);
  });

  const domProfile = useClickOutside(() => {
    setProfilePopover(false);
  });

  useEffect(
    () => {
      (async () => {
        if (debouncedSearchTerm) {
          setIsSearching(true);
          const users = await searchUsers(debouncedSearchTerm);
          if (users) {
            setResults(users);
            setIsSearching(false);
          }
        } else {
          setResults([]);
        }
      })();
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
  );

  if (!userInfo) return null;
  return (
    <Container>
      <LogoDiv
        onClick={() => {
          setLocation("/home");
        }}
      >
        <theme.logo />
      </LogoDiv>
      <SearchDiv ref={domSearch}>
        <StyledInput
          onClick={() => {
            setResultSearch(true);
          }}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            setResultSearch(true);
          }}
          placeholder="Search Social Lair"
          style={{ width: "400px", height: "35px", marginLeft: "30px" }}
          IconSrc={SearchIcon}
        />
        {showResultSearch && (
          <ResultSearch
            setResultSearch={setResultSearch}
            results={results}
            isSearching={isSearching}
          />
        )}
      </SearchDiv>
      <UserDiv>
        <Icon
          iconSVG={MoreIcon}
          onClick={() => {
            setLocation("/invite");
          }}
        />
        <Icon
          iconSVG={HomeIcon}
          onClick={() => {
            setLocation("/home");
          }}
        />
        <Icon
          iconSVG={MessageIcon}
          onClick={() => {
            setLocation("/messages");
          }}
        />
        <IconDiv ref={domNotifications}>
          <Icon
            iconSVG={NotificationIcon}
            style={
              showNotificationPopover ? { backgroundColor: "#0075bb" } : {}
            }
            onClick={() => {
              setNotificationPopover(!showNotificationPopover);
            }}
          />
          {showNotificationPopover && <NotificationPopover />}
        </IconDiv>

        <ProfileDiv
          ref={domProfile}
          style={{ color: showProfilePopover ? "#0075bb" : theme.colors.title }}
          onClick={() => {
            setProfilePopover(!showProfilePopover);
          }}
        >
          <ProfileImage alt="Profile Image" src={getImageUrl(userInfo.image)} />
          <ProfileName>{userInfo.name}</ProfileName>

          {showProfilePopover && <ProfilePopover userInfo={userInfo} />}
        </ProfileDiv>
      </UserDiv>
    </Container>
  );
}
