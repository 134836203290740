import React, { useRef, useState } from "react";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { useRecoilState } from "recoil";
import Button from "../StyledButton";
import InputSetting from "../InputSetting";
import { useModal } from "../Modal/Modal";
import ChangeImage from "../Modal/container/ChangeImage";
import { BUCKET_URL } from "../../utils/Url";
import readFile from "../../utils/ReadFile";
import { uploadImage } from "../../api/Image";
import { updateUser } from "../../api/User";
import userInfoAtom from "../../atoms/UserInfoAtom";
import getImageUrl from "../../utils/GetImageURL";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 700px;
`;

export const Title = styled.span`
  letter-spacing: 0.2px;
  color: ${(props) => props.theme.colors.text};
  font-size: 20px;
  font-weight: 600;
  margin: 37px 0px 0px 43px;
`;

export const Text = styled.span`
  letter-spacing: 0.16px;
  color: ${(props) => props.theme.colors.text};
  font-size: 16px;
  font-weight: 600;
  margin-left: 43px;
`;

export const Subtext = styled.span`
  font-size: 15px;
  letter-spacing: 0.15px;
  color: #818a91;
  margin: 2px 0px 0px 43px;
`;

const Profile = styled.div`
  display: grid;
  grid-template-areas:
    "image . "
    "image name "
    "image text "
    "image . ";
  grid-template-columns: 72px 200px;
  margin: 30px 75px 15px 75px;
  column-gap: 21px;
`;

const ProfileImage = styled.img`
  grid-area: image;
  width: 72px;
  height: 72px;
  border-radius: 72px;
`;

const ProfileName = styled.span`
  grid-area: name;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.18px;
  color: ${(props) => props.theme.colors.text};
  margin-bottom: 3px;
`;

const ProfileText = styled.span`
  grid-area: text;
  font-size: 16px;
  font-weight: medium;
  letter-spacing: 0.16px;
  color: #0075bb;
  cursor: pointer;
`;

const ModalTitle = styled.span`
  color: ${(props) => props.theme.colors.text};
  font-weight: 600;
  font-size: 18px;
`;

export default function EditProfile(): JSX.Element | null {
  const [userInfo, setUserInfo] = useRecoilState<any>(userInfoAtom);
  const theme = useTheme();
  const modal = useModal();
  const [disable, setDisable] = useState(false);
  const imageRef = useRef<HTMLInputElement>(null);
  const fullNameRef = useRef<HTMLInputElement>(null);
  const usernameRef = useRef<HTMLInputElement>(null);
  const bioRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const phoneRef = useRef<HTMLInputElement>(null);
  const [image, setImage] = useState<Blob | null>(null);
  const [isLoading, setLoading] = useState(false);

  const onFileChange = async (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const imageDataUrl: string = await readFile(file);

      modal.pushModal(
        <ChangeImage
          imageSrc={imageDataUrl}
          closeModal={() => modal.popModal()}
          saveImage={(croppedImage: Blob) => {
            setImage(croppedImage);
          }}
        />,
        <ModalTitle>Update Your Profile Photo</ModalTitle>
      );
    }
  };

  function checkInput(event: any) {
    if (event.target.value.length < 1) setDisable(true);
    else if (disable && event.target.value.length > 1) setDisable(false);
  }

  async function sendUpdateUser(imageId: string | null) {
    const success = await updateUser({
      uid: userInfo?.uid,
      name: fullNameRef.current?.value,
      username: usernameRef.current?.value,
      description: bioRef.current?.value,
      email: emailRef.current?.value,
      phone: phoneRef.current?.value,
      image: imageId || userInfo.image,
    });

    if (success)
      setUserInfo({
        ...userInfo,
        uid: userInfo?.uid,
        name: fullNameRef.current?.value,
        username: usernameRef.current?.value,
        description: bioRef.current?.value,
        email: emailRef.current?.value,
        phone: phoneRef.current?.value,
        image: imageId || userInfo.image,
      });

    return false;
  }

  if (!userInfo) return null;
  return (
    <Container>
      <Title>Your Profile</Title>
      <Profile>
        <ProfileImage
          src={image ? URL.createObjectURL(image) : getImageUrl(userInfo.image)}
        />
        <ProfileName>{userInfo.name}</ProfileName>
        <ProfileText onClick={() => imageRef.current?.click()}>
          <input
            ref={imageRef}
            type="file"
            accept="image/*"
            onChange={onFileChange}
            style={{ display: "none" }}
          />
          Change your profile picture
        </ProfileText>
      </Profile>

      <InputSetting
        ref={fullNameRef}
        onChange={checkInput}
        defaultValue={userInfo.name}
      >
        Full Name
      </InputSetting>

      <InputSetting
        ref={usernameRef}
        onChange={checkInput}
        defaultValue={userInfo.username}
      >
        Username
      </InputSetting>

      <InputSetting
        ref={bioRef}
        style={{
          borderBottom: "1px solid",
          borderColor: theme.colors.border,
          paddingBottom: "38px",
        }}
        onChange={() => {}}
        defaultValue={userInfo.description}
      >
        Bio
      </InputSetting>

      <Text style={{ paddingTop: "38px", marginLeft: "168px" }}>
        Personal Information
      </Text>
      <Subtext
        style={{
          marginLeft: "168px",
        }}
      >
        Provide your personal information. This won’t be part of your public
        profile.
      </Subtext>

      <InputSetting
        ref={emailRef}
        style={{ paddingTop: "25px" }}
        onChange={checkInput}
        defaultValue={userInfo.email}
      >
        Email Address
      </InputSetting>

      <InputSetting
        ref={phoneRef}
        style={{ paddingBottom: "0px" }}
        onChange={checkInput}
        defaultValue={userInfo.phone}
      >
        Phone number
      </InputSetting>

      <Button
        inverted
        style={{ alignSelf: "flex-end", margin: "30px 43px 49px 0px" }}
        disable={disable}
        isLoading={isLoading}
        onClick={async () => {
          setLoading(true);
          if (image) {
            const response = await uploadImage(image);
            if (response.status === 200) {
              setLoading(await sendUpdateUser(response.id));
            }
          } else {
            setLoading(await sendUpdateUser(null));
          }
        }}
      >
        Save
      </Button>
    </Container>
  );
}
