import React from "react";
import styled from "@emotion/styled";
import MessagesSideBar from "../components/Messages/MessagesSideBar";
import Messages from "../components/Messages/Messages";

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.background};
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: 415px auto;
  padding-top: 50px;
`;

export default function MessagesScreen(): JSX.Element {
  return (
    <Container>
      <MessagesSideBar />
      <Messages />
    </Container>
  );
}
