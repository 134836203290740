/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { DetailedHTMLProps, HTMLAttributes } from "react";
import styled from "@emotion/styled";
import { motion, AnimateSharedLayout } from "framer-motion";
import { Flex } from "../utils/HelperStyles";

interface Props
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  toggled: boolean;
  whenToggled: (toggled: boolean) => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
`;

const Label = styled.span`
  margin-right: 11px;
  font-size: 15px;
  font-weight: medium;
  letter-spacing: 0.15px;
  color: ${(props) => props.theme.colors.text};
`;

const Switch: any = styled.div`
  width: 40px;
  height: 16px;
  border-radius: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props: Props) => (props.toggled ? "#b2d5ea" : "#eceeef")};
  ${Flex};
  justify-content: space-around;
  position: relative;
  background-color: ${(props: Props) =>
    props.toggled ? "#b2d5ea" : "#eceeef"};
`;

const SwitchSelection: any = styled(motion.div)`
  width: 24px;
  height: 24px;
  background-color: ${(props: Props) =>
    props.toggled ? "#0075bb" : "#b1bbc6"};
  position: absolute;
  border-radius: 24px;
  z-index: 3;
`;

const SwitchItemArea = styled(motion.div)`
  height: 100%;
  width: 100%;
  position: relative;
  ${Flex};
  justify-content: center;
`;

export default function StyledSwitch(props: Props): JSX.Element {
  const { toggled, whenToggled } = props;

  return (
    <Container {...props}>
      <Label>{toggled ? "On" : "Off"}</Label>
      <Switch onClick={() => whenToggled(!props.toggled)} toggled={toggled}>
        <AnimateSharedLayout>
          <SwitchItemArea>
            {!toggled && <SwitchSelection toggled={toggled} layoutId="body" />}
          </SwitchItemArea>

          <SwitchItemArea>
            {toggled && <SwitchSelection toggled={toggled} layoutId="body" />}
          </SwitchItemArea>
        </AnimateSharedLayout>
      </Switch>
    </Container>
  );
}
