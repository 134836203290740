import React, {
  DetailedHTMLProps,
  HTMLAttributes,
  forwardRef,
  PropsWithChildren,
} from "react";
import styled from "@emotion/styled";
import { RefUtil } from "../utils/Types";
import { Flex } from "../utils/HelperStyles";

interface Props
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  onChange: (e: any) => void;
}

const Container = styled.div`
  ${Flex};
  flex-direction: row;
  padding: 15px 37px;
`;

const Label = styled.span`
  letter-spacing: 0.16px;
  color: ${(props) => props.theme.colors.text};
  font-size: 16px;
  font-weight: 600;
  text-align: right;
  width: 120px;
`;

const Input = styled.input`
  width: 489px;
  height: 46px;
  border: 1px solid;
  border-color: ${(props) => props.theme.colors.border};
  border-radius: 5px;
  text-indent: 14px;
  margin-left: 20px;
  background-color: ${(props) => props.theme.colors.card.background};
  color: ${(props) => props.theme.colors.text};
`;

function InputSetting(
  props: PropsWithChildren<Props>,
  ref: RefUtil<HTMLInputElement>
) {
  const { onChange, children, defaultValue } = props;
  return (
    <Container {...props}>
      <Label>{children}</Label>
      <Input ref={ref} defaultValue={defaultValue} onChange={onChange} />
    </Container>
  );
}

export default forwardRef<HTMLInputElement, Props>(InputSetting);
