/* eslint-disable no-underscore-dangle */
/** @jsxImportSource @emotion/react */
import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { BUCKET_URL } from "../../utils/Url";
import { Flex } from "../../utils/HelperStyles";
import { PostStatus, PostType } from "../../utils/Types";
import dateFromObjectId from "../../utils/DateFromObjectId";
import Button from "../StyledButton";
import { updatePost } from "../../api/Post";
import getImageUrl from "../../utils/GetImageURL";
import ImgGrid, { ImageGridCounter } from "../ImageGrid";

const Container = styled.div`
  ${Flex};
  flex-direction: column;
  border: 1px solid;
  background-color: ${(props) => props.theme.colors.card.background};
  border-color: ${(props) => props.theme.colors.card.border};
  border-radius: 8px;
  padding: 29px 22px;
  margin-bottom: 20px;
  width: 600px;
`;

const Heading = styled.div`
  width: 100%;
  ${Flex};
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 13px;
`;

const ProfileImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 40px;
  margin-right: 10px;
`;

const ProfileName = styled.span`
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.18px;
  color: ${(props) => props.theme.colors.text};
  margin-right: 8px;
  margin-top: 3px;
`;

const Schedule = styled.span`
  letter-spacing: 0px;
  color: #818a91;
  font-size: 14px;
  margin-top: 6px;
`;

const Content = styled.div`
  width: 100%;
  margin-bottom: 18px;
  color: ${(props) => props.theme.colors.text};
  word-wrap: break-word;
`;

const FlexDiv = styled.div`
  ${Flex};
`;

const Images = styled.div`
  ${Flex};
  margin-bottom: 10px;
`;

const Img = styled.img`
  max-width: 100%;
  min-width: 100%;

  height: 100%;
  max-height: 634px;
  object-fit: cover;
  background-color: #00000077;

  border-radius: 5px;
`;

interface Props {
  post: PostType;
  onChange: () => void;
  onImageClick: (index: number) => void;
}

export default function ManagePost(props: Props): JSX.Element {
  const { post, onChange, onImageClick } = props;

  async function changeStatusPost(status: string) {
    const success = await updatePost({ _id: post._id, status });
    if (success) onChange();
  }

  return (
    <Container>
      <Heading>
        <FlexDiv
          css={css`
            flex-direction: row;
          `}
        >
          <ProfileImage src={getImageUrl(post.authorInfo[0].image)} />
          <ProfileName> {post.authorInfo[0].name}</ProfileName>
          <Schedule> {dateFromObjectId(post._id)}</Schedule>
        </FlexDiv>
      </Heading>

      <Content>{post.content}</Content>

      <Images>
        {post.images && post.images.length > 0 && (
          <ImgGrid count={post.images.length}>
            {post.images.map((image: string, index) => {
              if (index >= 4) return null;
              return (
                <Img
                  className={index === 0 ? "first" : ""}
                  key={image}
                  src={getImageUrl(image)}
                  onClick={() => onImageClick(index)}
                />
              );
            })}
            {post.images.length > 4 && (
              <ImageGridCounter
                onClick={() => onImageClick(3)}
                className="noselect"
              >
                + {post.images.length}
              </ImageGridCounter>
            )}
          </ImgGrid>
        )}
      </Images>

      <FlexDiv>
        <Button
          inverted
          css={css`
            width: 268px;
            margin-right: 15px;
          `}
          onClick={async () => {
            await changeStatusPost(PostStatus.approved);
          }}
        >
          Approve
        </Button>
        <Button
          onClick={async () => {
            await changeStatusPost(PostStatus.refused);
          }}
          transparent
          css={css`
            width: 268px;
          `}
        >
          Decline
        </Button>
      </FlexDiv>
    </Container>
  );
}
