import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useLocation } from "wouter";
import { createUser, getUserByUid } from "../api/User";
import ImgLeft from "../assets/img/left-login.svg";
import { inviteUUIDAtom } from "../atoms/InviteUIDAtom";
import userInfoAtom from "../atoms/UserInfoAtom";
import UserDetails from "../components/CreateAccount/UserDetails";
import { AUTH_URL, SERVER_URL } from "../utils/Url";

const Wrapper = styled.div`
  padding-top: calc(50vh - 290px);
  width: 100vw;
  justify-content: center;
  display: flex;
  justify-content: center;
`;

const StyledImage = styled.img`
  width: 364px;
  height: 580px;
  @media screen and (max-width: 600px) {
    display: none;
  }
`;

const Container = styled.div`
  width: 825px;
  height: 580px;

  display: grid;
  grid-template-columns: 375px 450px;

  @media screen and (max-width: 600px) {
    display: flex;
    justify-content: center;
    border: none;
  }

  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e4e6e7;
`;

export default function CreateAccountScreen(): JSX.Element {
  const setUserInfo = useSetRecoilState(userInfoAtom);
  const inviteUUID = useRecoilValue(inviteUUIDAtom);
  const [authUserInfo, setUserAuthInfo] = useState<{
    userName: string;
    userEmail: string;
  } | null>(null);

  console.log({ recoilInviteUUID: inviteUUID });

  const [, setLocation] = useLocation();

  const uid = localStorage.getItem("@sociallair/uid");

  useEffect(() => {
    (async () => {
      if (!inviteUUID) {
        setLocation("/unauthorized");
        return;
      }
      const response = await fetch(`${SERVER_URL}/invite/${inviteUUID}`);

      const data = await response.json();

      if (!data.exists) {
        setLocation("/unauthorized");
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inviteUUID]);

  useEffect(() => {
    (async () => {
      const response = await fetch(`${AUTH_URL}/user/info/${uid}`);

      if (response.ok) {
        const data = await response.json();
        if (data.errors) {
          return console.log("Error message must be displayed");
        }

        const { userName, userEmail } = data.userInfo;
        setUserAuthInfo({ userEmail, userName });
      } else {
        return console.log("Error message must be displayed");
      }

      return null;
    })();
  }, [uid]);

  async function handleCreateUser(data: { name: string; username: string }) {
    const { name, username } = data;

    if (!authUserInfo || !uid) return;
    const response = await createUser({
      email: authUserInfo.userEmail,
      name,
      username,
      phone: authUserInfo.userName,
      uid,
    });

    if (!response) {
      console.log("Error message must be displayed");
      return;
    }

    const userInfo = await getUserByUid(uid);
    setUserInfo(userInfo);
    await fetch(`${SERVER_URL}/invite/${inviteUUID}`, {
      method: "DELETE",
    });

    setLocation("/home");
  }

  return (
    <Wrapper>
      <Container>
        <StyledImage src={ImgLeft} />
        <UserDetails createUser={handleCreateUser} />
      </Container>
    </Wrapper>
  );
}
