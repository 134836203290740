/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import { LairType } from "../utils/Types";
import { SERVER_URL } from "../utils/Url";

export async function getLairBytId(lairId: string): Promise<LairType | null> {
  const response: Response = await fetch(`${SERVER_URL}/lair/${lairId}`);

  if (response.status === 200) {
    const responseObject: LairType = await response.json();
    return responseObject;
  }

  return null;
}

export async function createLair(lair: any): Promise<number> {
  const response: Response = await fetch(`${SERVER_URL}/lair`, {
    method: "post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(lair),
  });

  return response.status;
}

export async function deleteLair(lairId: string): Promise<boolean> {
  const response: Response = await fetch(`${SERVER_URL}/lair/${lairId}`, {
    method: "delete",
  });

  if (response.status === 200) return true;
  return false;
}

export async function removeUserFromLair(
  lairId: string,
  userUid: string
): Promise<boolean> {
  const response: Response = await fetch(
    `${SERVER_URL}/lair/${lairId}/user/${userUid}`,
    { method: "delete" }
  );

  if (response.status === 200) return true;
  return false;
}

export async function addUserLair(
  lairId: string,
  userUid: string
): Promise<boolean> {
  const response: Response = await fetch(
    `${SERVER_URL}/lair/${lairId}/user/${userUid}`,
    { method: "post" }
  );

  if (response.status === 200) return true;
  return false;
}

export async function getLairByLairname(
  lairname: string
): Promise<LairType | null> {
  const response: Response = await fetch(
    `${SERVER_URL}/lair/lairname/${lairname}`
  );

  if (response.status === 200) {
    const responseObject: LairType = await response.json();
    return responseObject;
  }

  return null;
}

export async function updateLair(lair: any): Promise<boolean> {
  const id = lair._id;
  delete lair._id;
  const response: Response = await fetch(`${SERVER_URL}/lair/${id}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(lair),
  });

  if (response.status === 200) return true;
  return false;
}
