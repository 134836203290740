import styled from "@emotion/styled";
import React, { useState } from "react";
import { useLocation } from "wouter";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px;
`;

const Input = styled.input`
  border: 1px solid #cccccc;
  border-radius: 4px;
  font-size: 18px;
  padding: 10px;
`;

const Title = styled.h2`
  color: #2f2f2f;
  font-size: 24px;
`;

const Label = styled.div`
  color: #818a91;
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const Button = styled.button`
  background-color: #0075bb;
  border: none;
  color: #ffffff;
  border-radius: 5px;
  appearance: none;
  cursor: pointer;
  padding: 12px;
  font-size: 18px;
  width: 100%;
  :active {
    transform: translateY(1px);
  }
  :disabled {
    opacity: 50%;
  }
`;

const StyledLink = styled.a`
  color: #0075bb;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

const ButtonContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

interface IUserDetails {
  createUser: (data: { name: string; username: string }) => void;
}

export default function UserDetails(props: IUserDetails): JSX.Element {
  const { createUser } = props;
  const [, setLocation] = useLocation();

  const [name, setName] = useState<{ value: string; valid: boolean }>({
    value: "",
    valid: false,
  });
  const [userName, setUserName] = useState<{ value: string; valid: boolean }>({
    value: "",
    valid: false,
  });

  function handleChangeName(value: string) {
    setName({ value, valid: value !== "" });
  }
  function handleChangeUserName(value: string) {
    setUserName({ value, valid: value !== "" });
  }

  return (
    <Wrapper>
      <Title>Join SocialLair</Title>
      <Label>Display Name</Label>
      <Input
        onChange={(e) => handleChangeName(e.target.value)}
        type="text"
        value={name.value}
      />
      <Label>Username</Label>
      <Input
        onChange={(e) => handleChangeUserName(e.target.value)}
        type="text"
        value={userName.value}
      />
      <ButtonContainer>
        <Button
          onClick={() =>
            createUser({ name: name.value, username: userName.value })
          }
          disabled={!(name.valid && userName.valid)}
        >
          Join now!
        </Button>
      </ButtonContainer>
      <ButtonContainer>
        <StyledLink onClick={() => setLocation("/")}>
          Log Into Another Account
        </StyledLink>
      </ButtonContainer>
    </Wrapper>
  );
}
