/** @jsxImportSource @emotion/react */
import React from "react";
import styled from "@emotion/styled";
import { useRecoilValue } from "recoil";
import { css } from "@emotion/react";
import { useLocation } from "wouter";
import PendingPostIcon from "../../assets/icon/sidebar/pending-post.svg";
import SettingsIcon from "../../assets/icon/setting.svg";
import HomeIcon from "../../assets/icon/sidebar/home.svg";
import { IconType } from "../../utils/Types";
import { Flex } from "../../utils/HelperStyles";
import lairAtom from "../../atoms/LairAtom";
import { BUCKET_URL } from "../../utils/Url";

const List = styled.div`
  width: 221px;
  height: 100%;
  border-right: 1px solid;
  border-color: ${(props) => props.theme.colors.border};
  z-index: 10;
  padding-top: 10px;
  background-color: ${(props) => props.theme.colors.header};
`;

const ListItem = styled.div`
  ${Flex};
  height: 60px;
  color: ${(props) => props.theme.colors.text};
  font-size: 15px;
  cursor: pointer;
  &:hover {
    color: #0075bb;
    background-color: ${(props) => props.theme.colors.sidebar.hover};
    div {
      background-color: #0075bb;
    }
  }
`;

const Icon = styled.div`
  width: 24px;
  height: 24px;
  margin: 0px 10px 0px 30px;
  mask: url(${(props: IconType) => props.iconSVG}) no-repeat center;
  background-color: #b1bbc5;
`;

const Text = styled.span`
  font: normal normal medium 16px/20px;
  letter-spacing: 0.16px;
`;

const Title = styled.span`
  color: ${(props) => props.theme.colors.text};
  font-size: 18px;
  letter-spacing: 0.18px;
  font-weight: 600;
  ${Flex};
  margin: 10px 0px 10px 30px;
`;

const FlexDiv = styled.div`
  ${Flex}
  justify-content: center;
  margin: 20px;
`;

const LairImage = styled.img`
  width: 44px;
  height: 44px;
  border-radius: 44px;
  margin-right: 13px;
`;

const LairName = styled.span`
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.18px;
  color: ${(props) => props.theme.colors.text};
`;

const Count = styled.div`
  letter-spacing: 0.25px;
  color: #ffffff;
  font-size: 10px;
  font-weight: 600;
  background: #0676c0;
  padding: 5px;
  border-radius: 30px;
  margin: 0px 0px 0px 7px;
`;

export default function ManageLairSideBar(props: any): JSX.Element | null {
  const { setContent, content, count } = props;
  const lair = useRecoilValue(lairAtom);
  const [, setLocation] = useLocation();

  if (!lair) return null;
  return (
    <List>
      <FlexDiv>
        <LairImage src={`${BUCKET_URL}/file/${lair.image}`} />
        <LairName>{lair.name}</LairName>
      </FlexDiv>

      <Title>Manage Lair</Title>
      <ListItem onClick={() => setLocation(`/lair/${lair.lairname}`)}>
        <Icon iconSVG={HomeIcon} />
        <Text>Lair Home</Text>
      </ListItem>
      {lair.approvePosts && (
        <ListItem
          onClick={() => setContent(2)}
          css={css`
            color: ${content === 2 ? "#0075bb" : ""};
            div {
              background-color: ${content === 2 ? "#0075bb" : ""};
            }
          `}
        >
          <Icon iconSVG={PendingPostIcon} />
          <Text>Pending Post</Text>
          <Count>{count.pendingPost}</Count>
        </ListItem>
      )}

      <ListItem
        onClick={() => setContent(5)}
        css={css`
          color: ${content === 5 ? "#0075bb" : ""};
          div {
            background-color: ${content === 5 ? "#0075bb" : ""};
          }
        `}
      >
        <Icon iconSVG={SettingsIcon} />
        <Text>Lair Settings</Text>
      </ListItem>
    </List>
  );
}
