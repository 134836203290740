/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import StyledInput from "./StyledInput";
import UserCard from "./UserCard";
import { User } from "../utils/Types";
import SearchIcon from "../assets/icon/search.svg";
import { getUsers } from "../api/User";
import useDebounce from "../hooks/useDebounce";
import { Flex } from "../utils/HelperStyles";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${(props: any) =>
    props.color === "secondary"
      ? props.theme.colors.background_aux
      : props.theme.colors.card.background};
  border: 1px solid;
  border-color: ${(props: any) =>
    props.color === "secondary"
      ? props.theme.colors.border
      : props.theme.colors.card.border};
  border-radius: 8px;
`;

const Heading = styled.div`
  ${Flex};
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 30px 30px 0px;
`;

const Title = styled.span`
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.18px;
  color: ${(props) => props.theme.colors.text};
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 20px;
`;

interface Props {
  profileUser: User;
  color?: string;
}

export default function ConnectionsList(props: Props): JSX.Element {
  const { profileUser, color } = props;
  const [connections, setConnections] = useState<User[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [results, setResults] = useState<User[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    (async () => {
      const responseConnections = await getUsers(profileUser.connections);
      if (responseConnections) {
        setConnections(responseConnections);
        setResults(responseConnections);
      }
    })();
  }, [profileUser]);

  useEffect(() => {
    (async () => {
      if (debouncedSearchTerm) {
        setIsSearching(true);
        const filtered = connections.filter((connection) => {
          return (
            connection.name
              .toLowerCase()
              .indexOf(debouncedSearchTerm.toLowerCase()) > -1
          );
        });
        setResults(filtered);
      } else {
        setResults(connections);
      }
    })();
  }, [debouncedSearchTerm]);

  return (
    <Container color={color}>
      <Heading>
        <Title>Connections</Title>
        <StyledInput
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
          style={{ width: "300px", height: "40px" }}
          placeholder="Search Connections"
          IconSrc={SearchIcon}
          color={color}
        />
      </Heading>
      <Content>
        {results.length > 0 &&
          results.map((connection: User) => (
            <UserCard
              key={connection.uid}
              connection={connection}
              profileUserUid={profileUser.uid}
              color={color}
            />
          ))}
      </Content>
    </Container>
  );
}

ConnectionsList.defaultProps = {
  color: "primary",
};
