import styled from "@emotion/styled";
import msk from "msk";
import React, { useEffect, useState } from "react";
import { useLocation } from "wouter";
import { useRecoilState } from "recoil";
import { Country } from "../../utils/Types";
import { AUTH_URL } from "../../utils/Url";
import countries from "../../utils/countries.json";
import Button from "./Button";
import AuthHeader from "./AuthHeader";
import CountryFinderInput from "./CountryFinderInput";
import phoneAtom from "../../atoms/PhoneAtom";

const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  padding: 50px;
  @media screen and (max-width: 600px) {
    padding: 8vw;
  }
`;

const InputContainer = styled.div`
  width: 350px;
  height: 60px;
  margin-top: 10px;
  margin-bottom: 10px;
  @media screen and (max-width: 600px) {
    width: 85vw;
  }
`;

const PhoneInputContainer = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: center; */
`;

const CountryCodeInput = styled.input`
  width: 20%;
  padding: 15px 0px 15px 15px;
  font-size: 18px;
  appearance: none;
  border: 1px solid #cccccc;
  border-radius: 5px 0px 0px 5px;
  border-right: none;
  color: #2f2f2f;
  :focus {
    outline: none;
  }
`;
const PhoneTextInput = styled.input`
  width: calc(80% - 7.5px);
  padding: 15px 15px 15px 0;
  font-size: 18px;
  border: 1px solid #cccccc;
  border-radius: 0px 5px 5px 0px;
  color: #2f2f2f;
  border-left: none;
  appearance: none;
  :focus {
    outline: none;
  }
  ::placeholder {
    opacity: 50%;
  }
`;

const NextButtonContainer = styled.div`
  width: 100%;
  margin-top: 25px;
`;

function getMask(countryCode: string) {
  let result = "(999) 999-9999";
  switch (countryCode) {
    case "+1":
      result = "(999) 999-9999";
      break;
    case "+55":
      result = "99 9 9999-9999";
      break;
    case "+49":
      result = "99 999 999 999";
      break;
    default:
      result = "999999999999";
      break;
  }
  return result;
}

const ONLY_NUMBERS_REGEX = /\d+/g;

export default function PhoneInput() {
  const [countryCode, setCountryCode] = useState<string>("+1");
  const [phone, setPhone] = useState<string | undefined>(undefined);
  const [nextEnabled, setNextEnabled] = useState(false);
  const [, setPhoneState] = useRecoilState(phoneAtom);
  const [, setLocation] = useLocation();

  /**
   * Handles the country code changing both from inside the CountryFinderInput
   * and the input with the "+99..."
   *
   * If the change comes from inside the CountryFinderInput it ignores
   * the "hit country" check and just overwrites it
   *
   * @param code
   * @param force
   */
  function handleCountryCodeChange(code: string, options?: { force: boolean }) {
    // If the string is getting bigger, see if it hit a country
    // as countries have codes that are not inclusive (eg: if it
    // exists a +1 there can't be a +12), hitting a country means
    // we stop accepting more numbers into the input
    if (!options?.force && code.length > countryCode.length) {
      const country = countries.find((elem) => elem.code === `${countryCode}`);

      if (`${countryCode}` === country?.code) return;
    }
    const value = msk.fit(code, "9999");
    console.log("value", value);
    setCountryCode(`+${value}`);
  }

  function handlePhoneChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = msk.fit(event.target.value, getMask(countryCode));
    setPhone(value);
  }

  useEffect(() => {
    if (!phone) return;
    if (phone.length < 6) return;

    const phoneOnlyNumbers = phone.match(ONLY_NUMBERS_REGEX)?.join("");
    const parsedCountryCode = countryCode.split("+")[1];

    const fullPhone = `${parsedCountryCode}${phoneOnlyNumbers}`;

    const controller = new AbortController();
    const { signal } = controller;
    (async () => {
      setNextEnabled(false);
      const response = await fetch(`${AUTH_URL}/user/check_phone`, {
        signal,

        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },

        body: JSON.stringify({
          phone: fullPhone,
        }),
      });
      const responseJSON: { exists: true } = await response.json();

      console.log(responseJSON);

      setNextEnabled(responseJSON.exists);
    })();

    // eslint-disable-next-line consistent-return
    return () => {
      controller.abort();
    };
  }, [phone, countryCode]);

  function next() {
    if (!nextEnabled) return;

    let sendPhone = countryCode.replace("+", "");

    sendPhone += phone
      ?.replace("(", "")
      .replace(")", "")
      .replace("-", "")
      .replaceAll(" ", "");

    setPhoneState(sendPhone);
    setLocation("/password");
  }

  function handlePhoneOnkeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter") {
      next();
    }
  }

  return (
    <Container>
      <AuthHeader title="Login" subtitle="Enter your mobile number to login" />
      <InputContainer>
        <CountryFinderInput
          countryCode={countryCode}
          setCountryCode={() => {}}
          handleChangeCountry={(country, options) => {
            handleCountryCodeChange((country as Country).code, options);
          }}
        />
      </InputContainer>
      <InputContainer>
        <PhoneInputContainer>
          <CountryCodeInput
            onChange={(event) => {
              handleCountryCodeChange(event.target.value);
            }}
            value={countryCode}
            onKeyDown={handlePhoneOnkeyDown}
            inputMode="tel"
            pattern="[0-9]*"
          />
          <PhoneTextInput
            onChange={handlePhoneChange}
            value={phone}
            onKeyDown={handlePhoneOnkeyDown}
            placeholder={getMask(countryCode)}
            pattern="[0-9]*"
            inputMode="tel"
          />
        </PhoneInputContainer>

        <NextButtonContainer>
          <Button
            disabled={!nextEnabled}
            text="Next"
            width="100%"
            height="46px"
            onClick={next}
          />
        </NextButtonContainer>
      </InputContainer>
    </Container>
  );
}
