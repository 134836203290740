import React, {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  PropsWithChildren,
} from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { motion } from "framer-motion";
import { Flex } from "../utils/HelperStyles";

const NormalColors = css`
  /* background-color: white; */
  border-color: #0075bb;
  color: #0075bb;
`;

const InvertedColors = css`
  background-color: #0075bb;
  border-color: #0075bb;
  color: white;
`;

const Button = styled.button`
  background-color: ${(props) =>
    props.transparent ? "transparent" : props.theme.colors.background_aux};
  width: 120px;
  height: 36px;
  border: 1px solid #0075bb;
  border-radius: 5px;
  ${Flex};
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0px;
  ${(props: Props) => (props.inverted ? InvertedColors : NormalColors)}
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const Label = styled.span`
  opacity: ${(props: Props) => (props.disable ? 0.5 : 1)};
`;

const CircleLoading = styled.div`
  position: relative;
  width: 21px;
  height: 23px;
  box-sizing: border-box;
`;

const CircleStyle = styled(motion.span)`
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  border: 0.3rem solid #e9e9e9;
  border-top: 0.3rem solid #3498db;
  border-radius: 50%;
  position: absolute;
  box-sizing: border-box;
  top: 0;
  left: 0;
`;

const spinTransition = {
  loop: Infinity,
  ease: "linear",
  duration: 1,
};
export interface Props
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  inverted?: boolean;
  disable?: boolean;
  isLoading?: boolean;
  transparent?: boolean;
}

export default function StyledButton(
  props: PropsWithChildren<Props>
): JSX.Element {
  const { children, disable, isLoading } = props;
  return (
    <Button {...props} disabled={disable || isLoading}>
      {!isLoading ? (
        <Label disable={disable}>{children}</Label>
      ) : (
        <CircleLoading>
          <CircleStyle animate={{ rotate: 360 }} transition={spinTransition} />
        </CircleLoading>
      )}
    </Button>
  );
}
