/* eslint-disable no-underscore-dangle */
/** @jsxImportSource @emotion/react */
import React from "react";
import styled from "@emotion/styled";
import { useLocation } from "wouter";
import { useRecoilValue } from "recoil";
import { css } from "@emotion/react";
import { Flex } from "../../utils/HelperStyles";
import userInfoAtom from "../../atoms/UserInfoAtom";
import { BUCKET_URL } from "../../utils/Url";

const List = styled.div`
  width: 221px;
  height: 100%;
  border-right: 1px solid;
  border-color: ${(props) => props.theme.colors.border};
  /* position: fixed; */
  padding-top: 10px;
  z-index: 10;
  background-color: ${(props) => props.theme.colors.header};
  @media (max-width: 1000px) {
    display: none;
  }
`;

const LairList = styled.div`
  ${Flex};
  height: 60px;
  padding: 0px 0px 0px 30px;
  color: ${(props) => props.theme.colors.text};
  font-size: 15px;
  cursor: pointer;
  &:hover {
    color: #0075bb;
    background-color: ${(props) => props.theme.colors.sidebar.hover};
    div {
      background-color: #0075bb;
    }
  }
`;

const LairImage = styled.img`
  width: 44px;
  height: 44px;
  border-radius: 44px;
  margin-right: 13px;
`;

const LairName = styled.span`
  font-size: 16px;
  font-weight: medium;
  letter-spacing: 0.16px;
`;

const Title = styled.div`
  letter-spacing: 0.18px;
  color: ${(props) => props.theme.colors.text};
  font-size: 18px;
  font-weight: 600;
  margin: 10px 0px 10px 30px;
`;

export default function LairSidebar(): JSX.Element | null {
  const [location, setLocation] = useLocation();
  const userInfo = useRecoilValue(userInfoAtom);

  if (!userInfo) return null;
  return (
    <List>
      <Title>My Lairs</Title>
      {userInfo.lairs.map((lair) => (
        <LairList
          key={lair._id}
          onClick={() => {
            setLocation(`/lair/${lair.lairname}`);
          }}
          css={css`
            color: ${location.indexOf(lair.lairname) > -1 ? "#0075bb" : ""};
          `}
        >
          <LairImage src={`${BUCKET_URL}/file/${lair.image}`} />
          <LairName>{lair.name}</LairName>
        </LairList>
      ))}
    </List>
  );
}
