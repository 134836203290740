/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import styled from "@emotion/styled";

const Container: any = styled.div`
  display: grid;
  width: 100%;
  height: 100px;
  grid-template-columns: 40px auto;
  padding: 0px 40px;
  align-items: center;
  border-bottom: solid 1px ${(props) => props.theme.colors.card.border};
  cursor: pointer;
  border-left: ${(props: any) => (props.active ? "solid 5px #0075bb" : "none")};
  background-color: ${(props: any) =>
    props.active ? props.theme.colors.card.hover : "none"};

  &:hover {
    background-color: ${(props) => props.theme.colors.card.hover};
  }
`;

const UserImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 40px;
`;

const UserName = styled.span`
  color: ${(props) => props.theme.colors.text};
  font-style: 18px;
  font-weight: 600;
  letter-spacing: 0.18px;
`;

const LineOne = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Flex = styled.div`
  margin-left: 14px;
  display: flex;
  flex-direction: column;
`;

const LastMessage = styled.span`
  letter-spacing: 0.15px;
  font-style: 15px;
  color: #818a91;
`;

const Time = styled.span`
  display: flex;
  justify-content: flex-end;
  font-style: 12px;
  font-weight: medium;
  color: ${(props) => props.theme.colors.title};
`;

interface Props {
  active?: boolean;
}

export default function MessagesCard(props: Props): JSX.Element {
  const { active } = props;
  return (
    <Container active={active}>
      <UserImage src="https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg" />
      <Flex>
        <LineOne>
          <UserName>Gabriel Faria</UserName>
          <Time>10 MIN AGO</Time>
        </LineOne>
        <LastMessage>tete</LastMessage>
      </Flex>
    </Container>
  );
}

MessagesCard.defaultProps = {
  active: false,
};
