import React from "react";
import styled from "@emotion/styled";
import { useLocation } from "wouter";
import { Flex } from "../utils/HelperStyles";
import { LairType } from "../utils/Types";
import { BUCKET_URL } from "../utils/Url";

const Container = styled.div`
  ${Flex};
  justify-content: center;
  flex-direction: column;
  width: 275px;
  height: 275px;
  border: 1px solid;
  border-color: ${(props: any) =>
    props.color === "secondary"
      ? props.theme.colors.border
      : props.theme.colors.card.border};
  border-radius: 8px;
  margin: 10px 10px;
  cursor: pointer;
`;

const Image = styled.img`
  width: 132px;
  height: 132px;
  border-radius: 132px;
  margin-bottom: 24px;
`;

const Name = styled.span`
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.13px;
  color: ${(props) => props.theme.colors.text};
  margin-bottom: 8px;
`;

const Info = styled.div`
  letter-spacing: 0.13px;
  color: #818a91;
  font-size: 13px;
`;

interface Props {
  lair: LairType;
  color?: string;
}

export default function LairCard(props: Props): JSX.Element {
  const [, setLocation] = useLocation();
  const { lair, color } = props;
  return (
    <Container
      color={color}
      onClick={() => setLocation(`/lair/${lair.lairname}`)}
    >
      <Image src={`${BUCKET_URL}/file/${lair.image}`} />
      <Name> {lair.name}</Name>
      <Info> {lair.lairname}</Info>
    </Container>
  );
}

LairCard.defaultProps = {
  color: "secondary",
};
