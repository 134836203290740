import { css } from "@emotion/react";

const GlobalStyle = css`
  @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

  * {
    box-sizing: border-box;
    font-family: "Roboto", sans-serif;
    outline: none;
  }

  body {
    overflow: hidden;
    margin: 0px;
    padding: 0px;
  }

  *::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    border-radius: 3px;
    background-color: #f5f5f5;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #000000;
  }

  @font-face {
    font-family: SegoeUI;
    src: local("Segoe UI"), url("./fonts/Segoe U.ttf") format("truetype");
  }

  @font-face {
    font-family: SegoeUI;
    src: local("Segoe UI Bold"),
      url("./fonts/Segoe UI Bold.ttf") format("truetype");
    font-weight: 700;
  }

  @font-face {
    font-family: "SF Pro Display";
    font-weight: normal;
    src: local("SFProDisplay-Bold.ttf"),
      url("./fonts/SFProDisplay-Regular.ttf") format("truetype");
  }

  @font-face {
    font-family: "SF Pro Display Bold";
    font-weight: bold;
    src: local("SF Pro Display Bold"),
      url("./fonts/SFProDisplay-Bold.ttf") format("truetype");
  }

  .noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  }
`;

export default GlobalStyle;
