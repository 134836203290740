/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import { PostType } from "../utils/Types";
import { SERVER_URL } from "../utils/Url";
import { uploadImage } from "./Image";

export async function addPost(
  text: string,
  uid: string,
  lair: string | null,
  images: Blob[]
): Promise<boolean> {
  const imagesAux = await Promise.all(
    images.map(async (image) => {
      const response = await uploadImage(image);
      if (response.status === 200) {
        return response.id;
      }
      return null;
    })
  );

  const response = await fetch(`${SERVER_URL}/post`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      author: uid,
      content: text,
      images: imagesAux,
      lair,
    }),
    method: "POST",
  });

  if (response.status === 200) return true;
  return false;
}

export async function deletePost(id: string): Promise<boolean> {
  const response: Response = await fetch(`${SERVER_URL}/post/${id}`, {
    method: "DELETE",
  });
  if (response.status === 200) return true;
  return false;
}

export async function updatePost(post: any): Promise<boolean> {
  const id = post._id;
  delete post._id;
  const response: Response = await fetch(`${SERVER_URL}/post/${id}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(post),
  });

  if (response.status === 200) return true;
  return false;
}

export async function getPost(param: string): Promise<PostType> {
  const response: Response = await fetch(`${SERVER_URL}/post/${param}`);
  const responseObject: PostType = await response.json();
  return responseObject;
}

export async function getUserPosts(
  uid: string,
  lastPostId: string
): Promise<PostType[] | null> {
  const response = await fetch(`${SERVER_URL}/post/user/${uid}/${lastPostId}`);

  if (response.status === 200) {
    const responseObject = await response.json();
    return responseObject;
  }
  return null;
}

export async function getConnectionsPosts(
  uid: string,
  lastPostId: string
): Promise<PostType[] | null> {
  const response = await fetch(
    `${SERVER_URL}/post/connections/${uid}/${lastPostId}`
  );

  if (response.status === 200) {
    const responseObject = await response.json();
    return responseObject;
  }
  return null;
}

export async function getLairApprovedPost(
  id: string
): Promise<PostType[] | null> {
  const response = await fetch(`${SERVER_URL}/post/lair/${id}`);

  if (response.status === 200) {
    const responseObject = await response.json();
    return responseObject;
  }
  return null;
}

export async function getLairPendingPost(
  id: string
): Promise<PostType[] | null> {
  const response = await fetch(`${SERVER_URL}/post/lair/${id}/pending`);

  if (response.status === 200) {
    const responseObject = await response.json();
    return responseObject;
  }
  return null;
}

export async function addUpPost(
  postId: string,
  userUid: string
): Promise<boolean> {
  const response = await fetch(`${SERVER_URL}/post/up/${postId}/${userUid}`);
  if (response.status === 200) return true;
  return false;
}

export async function addDownPost(
  postId: string,
  userUid: string
): Promise<boolean> {
  const response = await fetch(`${SERVER_URL}/post/down/${postId}/${userUid}`);
  if (response.status === 200) return true;
  return false;
}
