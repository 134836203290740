import { CommentType } from "../utils/Types";
import { SERVER_URL } from "../utils/Url";

interface AddCommentBody {
  author: string;
  postId: string;
  content: string;
}
export async function addComment(body: AddCommentBody): Promise<boolean> {
  const response = await fetch(`${SERVER_URL}/comment`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify(body),
  });

  if (response.status === 200) return true;
  return false;
}

export async function addUpComment(
  commentId: string,
  postId: string,
  userUid: string
): Promise<boolean> {
  const response = await fetch(
    `${SERVER_URL}/comment/up/${commentId}/${postId}/${userUid}`
  );
  if (response.status === 200) return true;
  return false;
}

export async function addDownComment(
  commentId: string,
  postId: string,
  userUid: string
): Promise<boolean> {
  const response = await fetch(
    `${SERVER_URL}/comment/down/${commentId}/${postId}/${userUid}`
  );
  if (response.status === 200) return true;
  return false;
}
