import React from "react";
import styled from "@emotion/styled";
import Sidebar from "../components/SideBar";

const Container = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  background-color: ${(props) => props.theme.colors.header};
`;

const Content = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  background-color: ${(props) => props.theme.colors.background};
  margin-top: 54px;
  margin-left: 221px;
  overflow-y: scroll;
  justify-content: center;

  @media (max-width: 1000px) {
    margin-left: 0px;
  }
`;

interface Props {
  children: JSX.Element;
}

export default function HomeLayout(props: Props): JSX.Element {
  const { children } = props;
  return (
    <Container>
      <Sidebar />
      <Content>{children}</Content>
    </Container>
  );
}
