import React, { DetailedHTMLProps, HTMLAttributes } from "react";
import styled from "@emotion/styled";
import { IconType } from "../utils/Types";
import { Flex } from "../utils/HelperStyles";

export interface Props
  extends DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
  iconSVG: string;
  red?: boolean;
}

const Container = styled.div`
  width: 200px;
  height: 40px;
  ${Flex};
  flex-direction: row;
  margin: 12px 18px;
  cursor: pointer;
`;

const Icon: any = styled.div`
  mask: url(${(props: IconType) => props.iconSVG}) no-repeat center;
  background-color: ${(props: Props) => (props.red ? "#C00000" : "#b1bbc5")};
  width: 16px;
  height: 16px;
`;

const Text: any = styled.span`
  font-size: 15px;
  font-weight: medium;
  letter-spacing: 0.15px;
  color: ${(props: any) => (props.red ? "#C00000" : props.theme.colors.text)};
  margin-left: 10px;
`;

export default function IconButton(props: Props): JSX.Element {
  const { children, iconSVG, red, onClick } = props;
  return (
    <Container onClick={onClick}>
      <Icon iconSVG={iconSVG} red={red} />
      <Text red={red}>{children}</Text>
    </Container>
  );
}
