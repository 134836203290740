import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import msk from "msk";
import { useLocation } from "wouter";
import { useRecoilValue, useSetRecoilState } from "recoil";
import Button from "./Button";
import AuthHeader from "./AuthHeader";
import { AUTH_URL } from "../../utils/Url";
import userInfoAtom from "../../atoms/UserInfoAtom";
import phoneAtom from "../../atoms/PhoneAtom";
import { getUserByUid } from "../../api/User";

const Container = styled.div`
  width: 100%;
  padding: 50px;

  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 600px) {
    padding: 8vw;
    align-items: unset;
  }
`;

const InputContainer = styled.div`
  padding-top: 25px;
  height: 46px;
  @media screen and (max-width: 600px) {
    width: 85vw;
  }
`;

const SingleInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SingleInput = styled.input<{ error: boolean }>`
  width: 15%;
  padding: 11px 11px 11px 11px;
  font-size: 24px;
  appearance: none;
  border: 1px solid ${(props) => (props.error ? "#C00000" : "#cccccc")};
  border-radius: 5px;
  background-color: ${(props) => (props.error ? "#FAEBEB" : "#ffffff")};
  color: #2f2f2f;
  @media screen and (max-width: 600px) {
    width: 15vw;
  }
  text-align: center;
  :focus {
    outline: none;
  }
`;

const ErrorText = styled.div`
  color: #c00000;
  size: 14px;
`;

const NextButtonContainer = styled.div`
  width: 100%;
  margin-top: 25px;
`;

const LinkContainer = styled.div`
  width: 100%;
  margin-top: 100px;
  text-align: center;
`;

const CustomLink = styled.a`
  font-weight: 600;
  font-size: 16px;
  color: #0075bb;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

export default function PasswordInput(): JSX.Element {
  const [, setLocation] = useLocation();
  const setUserInfo = useSetRecoilState(userInfoAtom);
  const phone = useRecoilValue(phoneAtom);

  const [error, setError] = useState(false);
  const [sending, setSending] = useState<boolean>(false);

  const inputs = [
    useRef<HTMLInputElement | null>(null),
    useRef<HTMLInputElement | null>(null),
    useRef<HTMLInputElement | null>(null),
    useRef<HTMLInputElement | null>(null),
  ];

  const [password, setPassword] = useState<string[]>(["", "", "", ""]);

  useEffect(() => {
    inputs[0].current?.focus();
    // I just want this to run at the first time
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function doLogin(pass: Array<string | undefined>) {
    if (sending) return;

    setSending(true);
    const response = await fetch(`${AUTH_URL}/user/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        phone,
        password: pass.join(""),
      }),
    });
    const body = await response.json();
    // console.log(body);
    if (body.user) {
      localStorage.setItem("@sociallair/uid", body.user.uid);
      const userInfo = await getUserByUid(body.user.uid);
      if (!userInfo) {
        setLocation("/register");
        return;
      }
      setUserInfo(userInfo);
      setLocation("/home");
    } else {
      setError(true);
      console.log("error");
    }
    setSending(false);
  }

  function handlePasswordKeyPressed(
    event: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) {
    if (sending) return;
    const pass = [...password];
    if (index <= 3 && index >= -1) {
      switch (event.key) {
        case "Backspace":
          if (pass[index] === "") pass[index - 1] = "";
          else pass[index] = "";
          setPassword(pass);
          inputs[index - 1]?.current?.focus();
          return;
        case "ArrowLeft":
          inputs[index - 1]?.current?.focus();
          return;

        case "ArrowRight":
          inputs[index + 1]?.current?.focus();
          return;

        default:
          // If it's a number
          if (event.key.charCodeAt(0) >= 48 && event.key.charCodeAt(0) <= 57) {
            const maskedValue = msk.fit(event.key, "S");

            pass[index] = maskedValue;
            setPassword(pass);

            if (index < 3 && index >= -1) inputs[index + 1].current?.focus();
            else if (maskedValue !== "") doLogin(pass);
            setError(false);
          }

          break;
      }
    }
  }

  return (
    <Container>
      <AuthHeader title="Login" subtitle="Next, enter your 4-digit passcode" />
      <InputContainer>
        <SingleInputContainer>
          {inputs.map((_, index) => (
            <SingleInput
              // Sometimes index is a bad practice but here it's static
              // so it's a pass. I know what I'm doing
              onKeyDown={(evt) => handlePasswordKeyPressed(evt, index)}
              // eslint-disable-next-line react/no-array-index-key
              key={`input_${index}`}
              error={error}
              ref={inputs[index]}
              type="password"
              value={password[index]}
              pattern="[0-9]*"
              inputMode="numeric"
            />
          ))}
        </SingleInputContainer>
        {error && (
          <NextButtonContainer>
            <ErrorText>Incorrect Password</ErrorText>
          </NextButtonContainer>
        )}
        <NextButtonContainer>
          <Button
            disabled={sending}
            text="Login"
            onClick={() => doLogin(password)}
            width="100%"
          />
        </NextButtonContainer>
        <LinkContainer>
          <CustomLink onClick={() => setLocation("/")}>Go back</CustomLink>
        </LinkContainer>
      </InputContainer>
    </Container>
  );
}
