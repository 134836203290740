/** @jsxImportSource @emotion/react */
import React from "react";
import styled from "@emotion/styled";
import { useLocation } from "wouter";
import { useRecoilValue } from "recoil";
import { css } from "@emotion/react";
import HomeIcon from "../assets/icon/sidebar/home.svg";
import LairsIcon from "../assets/icon/sidebar/lairs.svg";
import ConnectionsIcon from "../assets/icon/sidebar/connections.svg";
import EventsIcon from "../assets/icon/sidebar/events.svg";
import { IconType } from "../utils/Types";
import { Flex } from "../utils/HelperStyles";
import userInfoAtom from "../atoms/UserInfoAtom";

const List = styled.div`
  width: 221px;
  height: 100%;
  border-right: 1px solid;
  border-color: ${(props) => props.theme.colors.border};
  margin-top: 54px;
  position: fixed;
  z-index: 99;
  padding-top: 10px;

  @media (max-width: 1000px) {
    display: none;
  }
`;

const ListItem = styled.div`
  ${Flex};
  height: 60px;
  color: ${(props) => props.theme.colors.text};
  font-size: 15px;
  cursor: pointer;
  &:hover {
    color: #0075bb;
    background-color: ${(props) => props.theme.colors.sidebar.hover};
    div {
      background-color: #0075bb;
    }
  }
`;

const Icon = styled.div`
  width: 24px;
  height: 24px;
  margin: 0px 10px 0px 30px;
  mask: url(${(props: IconType) => props.iconSVG}) no-repeat center;
  background-color: #b1bbc5;
`;

const Text = styled.span`
  font: normal normal medium 16px/20px;
  letter-spacing: 0.16px;
`;

export default function Sidebar(): JSX.Element {
  const [location, setLocation] = useLocation();

  return (
    <List>
      <ListItem
        onClick={() => setLocation("/home")}
        css={css`
          color: ${location.split("/home")[1] === "" ? "#0075bb" : ""};
          div {
            background-color: ${location.split("/home")[1] === ""
              ? "#0075bb"
              : ""};
          }
        `}
      >
        <Icon iconSVG={HomeIcon} />
        <Text>News Feed</Text>
      </ListItem>
      <ListItem
        onClick={() => setLocation("/home/lairs")}
        css={css`
          color: ${location.split("/home/")[1] === "lairs" ? "#0075bb" : ""};
          div {
            background-color: ${location.split("/home/")[1] === "lairs"
              ? "#0075bb"
              : ""};
          }
        `}
      >
        <Icon iconSVG={LairsIcon} />
        <Text>Lairs</Text>
      </ListItem>
      <ListItem
        onClick={() => setLocation("/home/connections")}
        css={css`
          color: ${location.split("/home/")[1] === "connections"
            ? "#0075bb"
            : ""};
          div {
            background-color: ${location.split("/home/")[1] === "connections"
              ? "#0075bb"
              : ""};
          }
        `}
      >
        <Icon iconSVG={ConnectionsIcon} />
        <Text>Connections</Text>
      </ListItem>
      <ListItem>
        <Icon iconSVG={EventsIcon} />
        <Text>Events</Text>
      </ListItem>
    </List>
  );
}
