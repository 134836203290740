import React, { useState } from "react";
import styled from "@emotion/styled";
import EditProfile from "../components/Settings/EditProfile";
import Preferences from "../components/Settings/Preferences";
import ChangePassword from "../components/Settings/ChangePassword";
import Privacy from "../components/Settings/Privacy";
import Notifications from "../components/Settings/Notifications";
import { Flex } from "../utils/HelperStyles";

const Wrapper = styled.div`
  height: fit-content;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 30px;
  margin-bottom: 60px;
`;

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.card.background};
  height: min-content;
  width: 936px;
  display: flex;
  flex-direction: row;
  border: 1px solid;
  border-color: ${(props) => props.theme.colors.border};
  border-radius: 8px;
`;

const SideBar = styled.div`
  width: 236px;
  border-right: 1px solid;
  border-color: ${(props) => props.theme.colors.border};
  padding-top: 12px;
`;

const Line = styled.div`
  height: 50px;
  letter-spacing: 0.16px;
  color: ${(props) => props.theme.colors.title};
  font-size: 16px;
  font-weight: medium;
  padding: 14px 29px;
  ${Flex};
  cursor: pointer;

  &:hover {
    background: ${(props) => props.theme.colors.card.hover};
    color: #0075bb;
  }
`;

const EDIT_PROFILE = 0;
const CHANGE_PASSWORD = 1;
const PREFERENCES = 2;
const PRIVACY = 3;
const NOTIFICATIONS = 4;

export default function SettingScreen(): JSX.Element {
  const [page, setPage] = useState(EDIT_PROFILE);

  return (
    <Wrapper>
      <Container>
        <SideBar>
          <Line
            onClick={() => {
              setPage(EDIT_PROFILE);
            }}
          >
            Edit Profile
          </Line>
          {/* <Line
            onClick={() => {
              setPage(CHANGE_PASSWORD);
            }}
          >
            Change Password
          </Line> */}
          <Line
            onClick={() => {
              setPage(PREFERENCES);
            }}
          >
            Preferences
          </Line>
          {/* <Line
            onClick={() => {
              setPage(PRIVACY);
            }}
          >
            Privacy
          </Line> */}
          <Line
            onClick={() => {
              setPage(NOTIFICATIONS);
            }}
          >
            Notifications
          </Line>
        </SideBar>
        {page === EDIT_PROFILE && <EditProfile />}
        {page === CHANGE_PASSWORD && <ChangePassword />}
        {page === PREFERENCES && <Preferences />}
        {page === PRIVACY && <Privacy />}
        {page === NOTIFICATIONS && <Notifications />}
      </Container>
    </Wrapper>
  );
}
