import React, { useRef, useState } from "react";
import styled from "@emotion/styled";
import Cropper from "react-avatar-editor";
import Button from "../../StyledButton";
import MinusSvg from "../../../assets/icon/minus.svg";
import PlusSvg from "../../../assets/icon/plus.svg";
import { Flex } from "../../../utils/HelperStyles";

const Container = styled.div`
  width: 400px;
  height: 550px;
`;

const Content = styled.div`
  width: 400px;
  height: 600px;
  ${Flex};
  flex-direction: column;
  position: relative;
`;

const CropperDiv = styled.div`
  position: absolute;
  width: 340px;
  height: 340px;
  margin-top: 40px;
`;

const Footer = styled.div`
  ${Flex};
  flex-direction: column;
  margin-top: 400px;
`;

const RangeDiv = styled.div`
  width: 340px;
  height: 40px;
  background-color: ${(props) => props.theme.colors.card.hover};
  border-radius: 20px;
  ${Flex};
  justify-content: center;
`;

const Range = styled.input`
  width: 250px;
  height: 4px;
  border-radius: 1px;

  & {
    margin-top: 3px;
    height: 4px;
    -webkit-appearance: none;
    background-color: ${(props) => props.theme.colors.search.text};
  }
  &::-webkit-slider-runnable-track {
    height: 40px;
    -webkit-appearance: none;
    color: blue;
    transition: box-shadow 0.2s ease-in-out;
    margin-top: 20px;
  }
  &::-webkit-slider-thumb {
    width: 20px;
    -webkit-appearance: none;
    height: 20px;
    cursor: ew-resize;
    background: #1597ff;
    box-shadow: inset 0 0 0 40px #fff;
    border-radius: 50%;
    transition: box-shadow 0.2s ease-in-out;
    position: relative;
  }
  &:active::-webkit-slider-thumb {
    background: #1597ff;
    box-shadow: inset 0 0 0 3px #fff;
  }
`;

const Buttons = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 52px;
`;

const Icon = styled.img`
  margin: 0px 10px;
  cursor: pointer;
`;

interface Props {
  imageSrc: string;
  closeModal: () => void;
  saveImage: (croppedImage: Blob) => void;
}

export default function ChangeImage(props: Props): JSX.Element {
  const { imageSrc, closeModal, saveImage } = props;
  const [zoom, setZoom] = useState(1);
  const editorRef = useRef<any>(null);

  function getCroppedImage() {
    const croppedImage = editorRef.current.getImage().toDataURL();

    fetch(croppedImage)
      .then((res) => res.blob())
      .then((blob) => saveImage(blob));

    closeModal();
  }

  return (
    <Container>
      <Content>
        <CropperDiv>
          <Cropper
            ref={editorRef}
            image={imageSrc}
            width={340}
            height={340}
            scale={zoom}
            borderRadius={340}
            border={0}
          />
        </CropperDiv>
        <Footer>
          <RangeDiv>
            <Icon
              src={MinusSvg}
              onClick={() => {
                if (zoom > 1) setZoom(zoom - 0.1);
              }}
            />
            <Range
              type="range"
              min="1"
              max="3"
              step="0.1"
              value={zoom}
              onChange={(event: any) => {
                setZoom(event.target.value);
              }}
            />
            <Icon
              src={PlusSvg}
              onClick={() => {
                if (zoom < 3) setZoom(zoom + 0.1);
              }}
            />
          </RangeDiv>
          <Buttons>
            <Button
              style={{ marginRight: "12px", width: "100px", height: "36px" }}
              onClick={closeModal}
              transparent
            >
              Cancel
            </Button>
            <Button
              style={{ width: "100px", height: "36px" }}
              inverted
              onClick={getCroppedImage}
            >
              Save
            </Button>
          </Buttons>
        </Footer>
      </Content>
    </Container>
  );
}
