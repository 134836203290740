import React, { useState } from "react";

import Button from "../StyledButton";
import { Container, Subtext, Text, Title } from "./EditProfile";
import SwitchSetting from "./SwitchSetting";

export default function Notifications(): JSX.Element {
  const [toggle, setToggle] = useState(false);
  return (
    <Container>
      <Title>Notifications</Title>
      <Subtext style={{ marginBottom: "28px" }}>
        What notifications you receive
      </Subtext>

      <Text>Upvotes</Text>
      <Subtext>
        Notifications for upvotes on your posts, comments and replies to
        comments
      </Subtext>

      <SwitchSetting
        style={{
          padding: "21px 0px 12px 0px",
          margin: "0px 43px",
        }}
        toggle={toggle}
        setToggle={(res: boolean) => {
          setToggle(res);
        }}
      >
        Push
      </SwitchSetting>

      <SwitchSetting
        style={{ paddingTop: "12px" }}
        toggle={toggle}
        setToggle={(res: boolean) => {
          setToggle(res);
        }}
      >
        Email
      </SwitchSetting>

      <Text style={{ marginTop: "25px" }}>Downvotes</Text>
      <Subtext>
        Notifications for downvotes on your posts, comments and replies to
        comments
      </Subtext>
      <SwitchSetting
        style={{
          padding: "21px 0px 12px 0px",
          margin: "0px 43px",
        }}
        toggle={toggle}
        setToggle={(res: boolean) => {
          setToggle(res);
        }}
      >
        Push
      </SwitchSetting>

      <SwitchSetting
        style={{ paddingTop: "12px" }}
        toggle={toggle}
        setToggle={(res: boolean) => {
          setToggle(res);
        }}
      >
        Email
      </SwitchSetting>

      <Text style={{ marginTop: "25px" }}>Comments</Text>
      <Subtext>
        Notifications for comments on your posts and replies to your comments
      </Subtext>
      <SwitchSetting
        style={{
          padding: "21px 0px 12px 0px",
          margin: "0px 43px",
        }}
        toggle={toggle}
        setToggle={(res: boolean) => {
          setToggle(res);
        }}
      >
        Push
      </SwitchSetting>

      <SwitchSetting
        style={{ paddingTop: "12px" }}
        toggle={toggle}
        setToggle={(res: boolean) => {
          setToggle(res);
        }}
      >
        Email
      </SwitchSetting>

      <Button
        inverted
        style={{ margin: "42px 43px 43px 0px", alignSelf: "flex-end" }}
      >
        Save
      </Button>
    </Container>
  );
}
