import React from "react";
import styled from "@emotion/styled";
import useLocation from "wouter/use-location";
import { User } from "../../utils/Types";
import ResultUserDiv from "./ResultUserDiv";

const Container = styled.div`
  z-index: 10;
  position: absolute;
  top: 50px;
  left: 30px;
  width: 400px;
  height: auto;
  background-color: ${(props) => props.theme.colors.background_aux};
  box-shadow: 0px 3px 8px #0000001f;
  border: 1px solid;
  border-color: ${(props) => props.theme.colors.border};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
`;

const ColorDiv = styled.div`
  color: ${(props) => props.theme.colors.text};
`;

interface Props {
  results: User[];
  isSearching: boolean;
  setResultSearch: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ResultSearch(props: Props): JSX.Element {
  const [location, setLocation] = useLocation();
  const { results, isSearching, setResultSearch } = props;

  function closeModal() {
    setResultSearch(false);
  }

  return (
    <Container>
      {isSearching ? (
        <ColorDiv>searching...</ColorDiv>
      ) : (
        <ColorDiv>
          {results.length === 0 ? (
            <div>No data</div>
          ) : (
            <div>
              {results.map((result) => (
                <ResultUserDiv
                  name={result.name}
                  image={result.image}
                  onClick={() => {
                    setLocation(`/sl/${result.username}`);
                    closeModal();
                  }}
                  username={result.username}
                />
              ))}
            </div>
          )}
        </ColorDiv>
      )}
    </Container>
  );
}
