/* eslint-disable no-underscore-dangle */
import styled from "@emotion/styled";
import React, { useState } from "react";
import { useLocation } from "wouter";
import Button from "../../../StyledButton";
import { LairType, User } from "../../../../utils/Types";
import { BUCKET_URL } from "../../../../utils/Url";
import { Flex } from "../../../../utils/HelperStyles";
import { addUserLair, getLairBytId } from "../../../../api/Lair";

const Card = styled.div`
  ${Flex};
  justify-content: space-between;
  flex-direction: row;
  width: 423px;
  height: 110px;
  border: 1px solid;
  border-color: ${(props: any) =>
    props.color === "secondary"
      ? props.theme.colors.border
      : props.theme.colors.card.border};
  border-radius: 8px;
  margin: 10px 10px;
`;

const Profile = styled.div`
  cursor: pointer;
  ${Flex};
  flex-direction: row;
`;

const Image = styled.img`
  width: 72px;
  height: 72px;
  margin: 0px 15px 0px 25px;
  border-radius: 72px;
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
`;

const Name = styled.span`
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.18px;
  color: ${(props) => props.theme.colors.text};
  margin-bottom: 2px;
`;

const Username = styled.div`
  letter-spacing: 0.15px;
  color: #818a91;
  font-size: 15px;
`;

export interface Props {
  user: User;
  lair: LairType;
  color?: string;
  setLair: (lair: LairType) => void;
}

export default function InviteUserCard(props: Props): JSX.Element {
  const { user, color, lair, setLair } = props;
  const [, setLocation] = useLocation();
  const [invited, setInvited] = useState(false);

  return (
    <Card color={color}>
      <Profile
        onClick={() => {
          setLocation(`/sl/${user.username}`);
        }}
      >
        <Image src={`${BUCKET_URL}/file/${user.image}`} />
        <Text>
          <Name>{user.name}</Name>
          <Username>{user.username}</Username>
        </Text>
      </Profile>
      <Button
        transparent
        disable={invited}
        inverted
        style={{ width: "100px", height: "36px", marginRight: "26px" }}
        onClick={async () => {
          const success = await addUserLair(lair._id, user.uid);
          if (success) {
            const lairFromAPI = await getLairBytId(lair._id);
            if (lairFromAPI) setLair(lairFromAPI);
            setInvited(true);
          }
        }}
      >
        {invited ? "Invited" : "Invite"}
      </Button>
    </Card>
  );
}

InviteUserCard.defaultProps = {
  color: "secondary",
};
