import styled from "@emotion/styled";
import React, { useState } from "react";
import StyledInput from "../components/StyledInput";
import SearchIcon from "../assets/icon/search.svg";
import StyledButton from "../components/StyledButton";
import { AUTH_URL, SERVER_URL } from "../utils/Url";

const Wrapper = styled.div`
  padding: 2vw;
  width: 100%;
  height: 100%;
  color: ${(props) => props.theme.colors.text};
`;

const Title = styled.h1`
  padding-left: 30px;
  font-size: 24px;
  font-weight: 600;
`;

const ButtonContainer = styled.div`
  padding: 30px;
`;

const ErrorSuccessDiv = styled.div`
  padding-left: 30px;
`;

export default function Invite(): JSX.Element {
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [sending, setSending] = useState<boolean>(false);

  async function sendInvite() {
    if (sending) return;
    try {
      setSending(true);
      const response = await fetch(`${AUTH_URL}/user/check_email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (data.exists) {
        await fetch(`${SERVER_URL}/invite`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        });
        setSuccess(true);
        setError(false);
      } else {
        setSuccess(false);
        setError(true);
      }
    } catch {
      setSuccess(false);
      setError(true);
    } finally {
      setSending(false);
    }
  }

  return (
    <Wrapper>
      <Title>Invite users from learnistic</Title>

      <StyledInput
        onChange={(e) => {
          setEmail(e.target.value);
          setError(false);
          setSuccess(false);
        }}
        type="email"
        placeholder="Enter an email"
        style={{ width: "400px", height: "35px", marginLeft: "30px" }}
        IconSrc={SearchIcon}
      />
      <ErrorSuccessDiv>
        {error && "User not found!"}
        {success && "User invited with success!"}
      </ErrorSuccessDiv>
      <ButtonContainer>
        <StyledButton disabled={sending} onClick={sendInvite}>
          Send Invite
        </StyledButton>
      </ButtonContainer>
    </Wrapper>
  );
}
