/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-underscore-dangle */
/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { useRecoilValue } from "recoil";
import { useRoute } from "wouter";
import Post from "../Post";
import { PostType, User } from "../../utils/Types";
import { getPost, getUserPosts } from "../../api/Post";
import userInfoAtom from "../../atoms/UserInfoAtom";
import { Flex } from "../../utils/HelperStyles";
import ImageCarousel from "../ImageCarousel";

const Wrapper = styled.div`
  ${Flex};
  flex-direction: column;
  height: fit-content;
  cursor: default;
  width: 100%;
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 650px 290px;
`;

const FeedContainer = styled.div`
  ${Flex};
  flex-direction: column;
  width: 600px;
`;

const FeedWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ActivitiesContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Activities: any = styled.div`
  width: 100%;
  height: 600px;
  background-color: ${(props: any) =>
    props.color === "secondary"
      ? props.theme.colors.background_aux
      : props.theme.colors.card.background};
  border: 1px solid ${(props) => props.theme.colors.card.border};
  border-radius: 8px;
`;

interface Props {
  author: User;
}

export default function ProfileActivity(props: Props): JSX.Element | null {
  const [posts, setPosts] = useState<PostType[]>([]);
  const userInfo = useRecoilValue(userInfoAtom);
  const [lastPostId, setLastPostId] = useState("");
  const [loading, setLoading] = useState(false);
  const wrapperRef = useRef<any>(null);
  const { author } = props;
  const [, params] = useRoute<any>("/sl/:username");
  const [selectedPostImage, setSelectedPostImage] = useState<{
    post: PostType;
    imageIndex: number;
  } | null>(null);

  async function modifyPost(postId: string) {
    const updatedPost = await getPost(postId);
    const updatedPosts: PostType[] = posts.map((postAux: PostType) => {
      if (postAux._id === updatedPost._id) return updatedPost;
      return postAux;
    });
    setPosts(updatedPosts);
  }

  function removePost(postId: string) {
    const newPosts: PostType[] = posts.filter(
      (postAux: PostType) => postAux._id !== postId
    );
    setPosts(newPosts);
  }

  // useEffect used to * reset * posts when going to another user's page
  useEffect(() => {
    if (params?.username !== author.username) return;
    (async () => {
      const postResponse = await getUserPosts(author.uid, "");
      if (postResponse) {
        setPosts(postResponse);
        if (postResponse.length > 0)
          setLastPostId(postResponse[postResponse.length - 1]._id);
        else setLastPostId("");

        setLoading(false);
      }
    })();
  }, [params?.username, author]);

  useEffect(() => {
    if (!loading) return;
    (async () => {
      const postResponse = await getUserPosts(author.uid, lastPostId);
      if (postResponse && postResponse.length > 1) {
        setLastPostId(postResponse[postResponse.length - 1]._id);
        setPosts([...posts, ...postResponse]);
        setLoading(false);
      }
    })();
  }, [loading]);

  useEffect(() => {
    if (loading) return () => {};
    function handleScroll() {
      if (!wrapperRef.current) return;
      if (
        wrapperRef.current.parentElement.parentElement.parentElement
          .clientHeight +
          wrapperRef.current.parentElement.parentElement.parentElement
            .scrollTop <
        wrapperRef.current.parentElement.parentElement.parentElement
          .scrollHeight -
          200
      )
        return;
      setLoading(true);
    }

    wrapperRef.current.parentElement.parentElement.parentElement.addEventListener(
      "scroll",
      handleScroll
    );

    return () => {
      if (wrapperRef.current)
        wrapperRef.current.parentElement.parentElement.parentElement.removeEventListener(
          "scroll",
          handleScroll
        );
    };
  }, [loading, author]);

  if (!author || !userInfo) return <Wrapper ref={wrapperRef} />;
  return (
    <Wrapper ref={wrapperRef}>
      <Container>
        <FeedWrapper>
          {selectedPostImage && (
            <ImageCarousel
              onClosePressed={() => setSelectedPostImage(null)}
              images={selectedPostImage.post.images || []}
              initialSlide={selectedPostImage.imageIndex || 0}
            />
          )}

          <FeedContainer>
            {posts.map((post: PostType) => (
              <Post
                key={`post_${post._id}_${post.upVotes.length}_${post.downVotes.length}`}
                color="secondary"
                onDelete={() => removePost(post._id)}
                post={post}
                screen="profile"
                userInfo={userInfo}
                onChange={async () => {
                  await modifyPost(post._id);
                }}
                onImageClick={(imageIndex) =>
                  setSelectedPostImage({ post, imageIndex })
                }
              />
            ))}
          </FeedContainer>
        </FeedWrapper>

        <ActivitiesContainer>
          <Activities color="secondary" />
        </ActivitiesContainer>
      </Container>
    </Wrapper>
  );
}
