/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import StyledInput from "../StyledInput";
import UserCard from "../UserCard";
import { User } from "../../utils/Types";
import SearchIcon from "../../assets/icon/search.svg";
import useDebounce from "../../hooks/useDebounce";
import { Flex } from "../../utils/HelperStyles";
import StyledButton from "../StyledButton";
import { useModal } from "../Modal/Modal";
import InviteUserToLair from "../Modal/container/InviteUserToLair/InviteUserToLair";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${(props) => props.theme.colors.background_aux};
  border: 1px solid;
  border-color: ${(props) => props.theme.colors.border};
  border-radius: 8px;
`;

const Heading = styled.div`
  ${Flex};
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 30px 30px 0px;
`;

const Title = styled.span`
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.18px;
  color: ${(props) => props.theme.colors.text};
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 20px;
`;

const RightHeader = styled.div`
  ${Flex}
  flex-direction: row;
`;

interface Props {
  members: User[];
  admin: boolean;
}

export default function MembersList(props: Props): JSX.Element {
  const { members, admin } = props;
  const [searchTerm, setSearchTerm] = useState("");
  const [results, setResults] = useState<User[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const modal = useModal();

  useEffect(() => {
    setResults(members);
  }, [members]);

  useEffect(() => {
    (async () => {
      if (debouncedSearchTerm) {
        setIsSearching(true);
        const filtered = members.filter((member) => {
          return (
            member.name
              .toLowerCase()
              .indexOf(debouncedSearchTerm.toLowerCase()) > -1
          );
        });
        setResults(filtered);
      } else {
        setResults(members);
      }
    })();
  }, [debouncedSearchTerm]);

  return (
    <Container>
      <Heading>
        <Title>Members</Title>
        <RightHeader>
          {admin && (
            <StyledButton
              transparent
              onClick={() => {
                modal.pushModal(
                  <InviteUserToLair closeModal={modal.popModal} />,
                  <Title>Invite</Title>
                );
              }}
            >
              Invite
            </StyledButton>
          )}
          <StyledInput
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            style={{ width: "300px", height: "40px", marginLeft: "20px" }}
            placeholder="Search Members"
            IconSrc={SearchIcon}
            color="secondary"
          />
        </RightHeader>
      </Heading>
      <Content>
        {results.length > 0 &&
          results.map((member: User) => (
            <UserCard
              key={member.uid}
              connection={member}
              profileUserUid=""
              type="lair"
            />
          ))}
      </Content>
    </Container>
  );
}
