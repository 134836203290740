import { BUCKET_URL } from "../utils/Url";

// FAZER O TREM PARA APAGAR A IMAGEM ANTERIOR

interface UploadImageResponse {
  id: string;
  status: number;
}

export async function uploadImage(image: Blob): Promise<UploadImageResponse> {
  const formData = new FormData();
  const BlobToFile = new File([image], "image.png", {
    type: "image/png",
  });
  formData.append("file", BlobToFile);

  const reponse = await fetch(`${BUCKET_URL}/file/compressed`, {
    body: formData,
    method: "POST",
  });

  const responseObject = await reponse.json();

  return { ...responseObject, status: reponse.status };
}
