/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-underscore-dangle */
/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useRecoilValue } from "recoil";
import { useRoute } from "wouter";
import userInfoAtom from "../atoms/UserInfoAtom";
import Feed from "../components/Home/Feed";
import LairsList from "../components/LairsList";
import Connections from "../components/ConnectionsList";

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  margin: 30px 0px;
  width: 940px;
  border-radius: 4px;
`;

export default function HomeScreen(): JSX.Element {
  const userInfo = useRecoilValue(userInfoAtom);
  const [, params] = useRoute<any>("/home/:content");
  const [content, setContent] = useState<string | null>();

  useEffect(() => {
    params ? setContent(params.content) : setContent(null);
  }, [params?.content]);

  // I left the wrapper instead of null so the white background doesn't appear if it's a dark theme
  if (!userInfo) return <Container />;
  return (
    <Container>
      {content === null && <Feed />}
      {content === "lairs" && (
        <Content>
          <LairsList profileUser={userInfo} />
        </Content>
      )}
      {content === "connections" && (
        <Content>
          <Connections profileUser={userInfo} />
        </Content>
      )}
    </Container>
  );
}
