import { Theme } from "@emotion/react";
import { LogoDark } from "../../assets/img/logo-dark";

const dark: Theme = {
  title: "dark",

  logo: LogoDark,

  colors: {
    title: "#B1BBC6",
    text: "#FFFFFF",
    header: "#151515",
    background: "#222222",
    background_aux: "#242526",
    border: "#38393A",

    sidebar: {
      hover: "#222324",
    },

    card: {
      background: "#2F2F2F",
      hover: "#242526",
      border: " #ECEEEF1A",
    },

    search: {
      background: "#2F2F2F",
      text: "#6A6F74",
    },
  },
};

export default dark;
