import React, { DetailedHTMLProps, HTMLAttributes } from "react";
import styled from "@emotion/styled";
import Switch from "../StyledSwitch";
import { Flex } from "../../utils/HelperStyles";

interface Props
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  setToggle: (toggle: boolean) => void;
  toggle: boolean;
}

const Container = styled.div`
  ${Flex};
  justify-content: space-between;
  padding: 20px 43px;
  border-bottom: 1px solid;
  border-color: ${(props) => props.theme.colors.border};
`;

const Label = styled.span`
  font-size: 15px;
  font-weight: medium;
  letter-spacing: 0.15px;
  color: ${(props) => props.theme.colors.text};
`;

export default function SwitchSetting(props: Props): JSX.Element {
  const { toggle, setToggle, children } = props;
  return (
    <Container {...props}>
      <Label>{children}</Label>
      <Switch
        toggled={toggle}
        whenToggled={(res) => {
          setToggle(res);
        }}
      />
    </Container>
  );
}
