// Nesse arquivo eu não estou me importando com gente cega
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import styled from "@emotion/styled";
import { motion } from "framer-motion";
import {
  ButtonBack,
  ButtonFirst,
  ButtonLast,
  ButtonNext,
  CarouselContext,
  CarouselProvider,
  DotGroup,
  ImageWithZoom,
  Slide,
  Slider,
} from "pure-react-carousel";
import React, { useContext, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import getImageUrl from "../utils/GetImageURL";

const Container = styled(motion.div)`
  position: fixed;
  top: 0px;
  left: 0px;

  width: 100vw;
  height: 100vh;

  z-index: 9999;
  background-color: #000000aa;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const Img = styled.img`
  max-width: 100%;
  min-width: 100%;

  height: 100%;
  max-height: 634px;
  object-fit: contain;
  background-color: #00000077;

  border-radius: 5px;
`;

const ZoomableContainer = styled.div`
  width: 100%;
  height: 100%;
  cursor: zoom-out !important;
`;

const CarouselContainer = styled.div`
  width: fit-content;
  height: fit-content;

  position: relative;
`;

const CarouselButton = styled.div`
  width: 50px;
  height: calc(100% - 44px);

  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 50px;

  background-color: #00000077;
  position: absolute;
`;

interface Props {
  images: string[];
  onClosePressed: () => void;
  initialSlide: number;
}

export default function ImageCarousel(props: Props): JSX.Element {
  const { images, onClosePressed, initialSlide } = props;
  const [currentSlide, setCurrentSlide] = useState(initialSlide);

  /**
   * This Carousel component has an ugly nonfunctional Context,
   * but the rest of it works nicely.
   *
   * So I'm mirrorring what page he's in
   */

  return (
    <Container
      onClick={onClosePressed}
      animate={{ opacity: 1, y: 0 }}
      initial={{ opacity: 0, y: 100 }}
    >
      <CarouselContainer onClick={(e) => e.stopPropagation()}>
        <CarouselProvider
          visibleSlides={1}
          totalSlides={images.length}
          step={1}
          dragStep={1}
          naturalSlideWidth={window.innerWidth * 0.6}
          naturalSlideHeight={window.innerHeight * 0.7}
          currentSlide={currentSlide}
        >
          <Slider
            style={{
              width: window.innerWidth * 0.6,
              height: window.innerHeight * 0.7,
            }}
          >
            {images.map((imageUrl, index) => (
              <Slide key={imageUrl} index={index}>
                {/* For browsers we click to zoom */}

                {!isMobile && (
                  <Img alt="Carousel" src={getImageUrl(imageUrl)} />
                )}

                {/* For mobile the ImageWithZoom supports pinching */}
                {isMobile && (
                  <ZoomableContainer>
                    <ImageWithZoom src={getImageUrl(imageUrl)} />
                  </ZoomableContainer>
                )}
              </Slide>
            ))}
          </Slider>
        </CarouselProvider>

        <CarouselButton
          className="noselect"
          style={{ right: 0, top: 0 }}
          onClick={() =>
            setCurrentSlide((c) => (c >= images.length - 1 ? 0 : c + 1))
          }
        >
          ›
        </CarouselButton>
        <CarouselButton
          className="noselect"
          style={{ left: 0, top: 0 }}
          onClick={() =>
            setCurrentSlide((c) => (c <= 0 ? images.length - 1 : c - 1))
          }
        >
          ‹
        </CarouselButton>
      </CarouselContainer>
    </Container>
  );
}
