/* eslint-disable no-underscore-dangle */
/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useRecoilState, useRecoilValue } from "recoil";
import userInfoAtom from "../../../../atoms/UserInfoAtom";
import useDebounce from "../../../../hooks/useDebounce";
import { LairType, User } from "../../../../utils/Types";
import { searchUsers } from "../../../../api/User";
import StyledInput from "../../../StyledInput";
import SearchIcon from "../../../../assets/icon/search.svg";
import InviteUserCard from "./InviteUserCard";
import lairAtom from "../../../../atoms/LairAtom";

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.card.background};
  border-radius: 8px;
  height: min-content;
  display: flex;
  flex-direction: column;
  width: 940px;
  max-height: 600px;
  min-height: 360px;
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 20px;
  overflow: auto;
`;

export default function InviteUserToLair(props: any): JSX.Element | null {
  const { closeModal } = props;
  const userInfo = useRecoilValue(userInfoAtom);
  const [lair, setLair] = useRecoilState<any>(lairAtom);
  const [searchTerm, setSearchTerm] = useState("");
  const [results, setResults] = useState<User[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(
    () => {
      (async () => {
        if (debouncedSearchTerm) {
          setIsSearching(true);
          const users = await searchUsers(debouncedSearchTerm);
          if (users) {
            setResults(users);
            setIsSearching(false);
          }
        } else {
          setResults([]);
        }
      })();
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
  );

  if (!userInfo || !lair) return null;
  return (
    <Container>
      <StyledInput
        onChange={(e) => {
          setSearchTerm(e.target.value);
        }}
        placeholder="Search Social Lair"
        style={{ width: "400px", height: "35px", margin: "20px 0px 0px 10px" }}
        IconSrc={SearchIcon}
      />
      <Content>
        {results &&
          results.map((result) => {
            const verifyCheck = result.lairs.filter(
              (element) => element._id === lair._id
            );
            if (verifyCheck.length > 0) return null;
            return (
              <InviteUserCard
                key={result.uid}
                user={result}
                color="primary"
                lair={lair}
                setLair={(lairFromAPI: LairType) => {
                  setLair(lairFromAPI);
                }}
              />
            );
          })}
      </Content>
    </Container>
  );
}
