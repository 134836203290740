// /* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import styled from "@emotion/styled";
import { useRoute } from "wouter";
import { User } from "../utils/Types";
import ProfileActivity from "../components/Profile/ProfileActivity";
import { getUserByUsername } from "../api/User";
import Profile from "../components/Profile/Profile";
import { Flex } from "../utils/HelperStyles";
import LairsList from "../components/LairsList";
import Connections from "../components/ConnectionsList";
import userInfoAtom from "../atoms/UserInfoAtom";

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colors.background};
  height: 100vh;
  overflow: scroll;
  padding-top: 50px;
`;

const Container = styled.div`
  position: relative;
  ${Flex};
  justify-content: center;
  flex-direction: column;
`;

const Content = styled.div`
  margin: 30px 0px;
  width: 940px;
  border-radius: 4px;
`;

export default function ProfileScreen(): JSX.Element {
  const [, paramsWithContent] = useRoute<any>("/sl/:username/:content");
  const [, params] = useRoute<any>("/sl/:username");
  const [profileUser, setProfileUser] = useState<User | null>();
  const [content, setContent] = useState("");
  const userInfo = useRecoilValue(userInfoAtom);

  async function initProfileScreen(username: string) {
    const user = await getUserByUsername(username);
    setProfileUser(user);
  }

  useEffect(() => {
    if (!userInfo) return;
    (async () => {
      if (paramsWithContent && paramsWithContent.username) {
        if (paramsWithContent.username !== profileUser?.username)
          await initProfileScreen(paramsWithContent.username);
        setContent(paramsWithContent.content);
      } else {
        // if (params.username !== profileUser?.username)
        await initProfileScreen(params.username);
        setContent("");
      }
    })();
  }, [params?.username, paramsWithContent?.content]);

  // To update the user when make a connection/disconnection with other user
  useEffect(() => {
    if (!userInfo) return;
    (async () => {
      if (paramsWithContent && paramsWithContent.username) {
        await initProfileScreen(paramsWithContent.username);
      } else {
        await initProfileScreen(params.username);
      }
    })();
  }, [userInfo]);

  // I left the wrapper instead of null so the white background doesn't appear if it's a dark theme
  if (!profileUser) return <Wrapper />;
  return (
    <Wrapper>
      <Container>
        <Profile profileUser={profileUser} />
        <Content>
          {content === "" && <ProfileActivity author={profileUser} />}
          {content === "connections" && (
            <Connections profileUser={profileUser} color="secondary" />
          )}
          {content === "lairs" && (
            <LairsList profileUser={profileUser} color="secondary" />
          )}
        </Content>
      </Container>
    </Wrapper>
  );
}
