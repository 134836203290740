/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-underscore-dangle */
/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { useRecoilValue } from "recoil";
import { useModal } from "../Modal/Modal";
import Post from "../Post";
import { PostType } from "../../utils/Types";
import { getConnectionsPosts, getPost } from "../../api/Post";
import userInfoAtom from "../../atoms/UserInfoAtom";
import { Flex } from "../../utils/HelperStyles";
import StartDiscussion from "../StarDiscussion";
import ImageCarousel from "../ImageCarousel";

const Wrapper = styled.div`
  ${Flex};
  flex-direction: column;
  height: fit-content;
  margin-bottom: 60px;
  padding: 40px 50px 50px 50px;
  cursor: default;
  width: 100%;
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 730px 320px;
`;

const FeedContainer = styled.div`
  ${Flex};
  flex-direction: column;
  width: 640px;
`;

const FeedWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ActivitiesContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Activities = styled.div`
  width: 100%;
  height: 600px;
  background-color: ${(props) => props.theme.colors.card.background};
  border: 1px solid ${(props) => props.theme.colors.card.border};
  border-radius: 8px;
`;

export default function Feed(): JSX.Element {
  const [posts, setPosts] = useState<PostType[]>([]);
  const userInfo = useRecoilValue(userInfoAtom);
  const wrapperRef = useRef<any>(null);
  const [lastPostId, setLastPostId] = useState("");
  const [loading, setLoading] = useState(true); // Starts as true to be able to make the first post request

  const [selectedPostImage, setSelectedPostImage] = useState<{
    post: PostType;
    imageIndex: number;
  } | null>(null);

  async function loadPosts() {
    if (!userInfo) return;
    const postResponse = await getConnectionsPosts(userInfo.uid, "");
    if (postResponse) {
      setLastPostId(postResponse[postResponse.length - 1]._id);
      setPosts(postResponse);
    }
  }

  async function modifyPost(postId: string) {
    const updatedPost = await getPost(postId);
    const updatedPosts: PostType[] = posts.map((postAux: PostType) => {
      if (postAux._id === updatedPost._id) return updatedPost;
      return postAux;
    });
    setPosts(updatedPosts);
  }

  function removePost(postId: string) {
    const newPosts: PostType[] = posts.filter(
      (postAux: PostType) => postAux._id !== postId
    );
    setPosts(newPosts);
  }

  useEffect(() => {
    if (!userInfo || !loading) return;
    (async () => {
      const postResponse = await getConnectionsPosts(userInfo.uid, lastPostId);
      if (postResponse && postResponse.length > 1) {
        setLastPostId(postResponse[postResponse.length - 1]._id);
        setPosts([...posts, ...postResponse]);
        setLoading(false);
      }
    })();
  }, [loading]);

  useEffect(() => {
    if (loading) return () => {};

    function handleScroll() {
      if (!wrapperRef.current) return;
      if (
        wrapperRef.current.parentElement.parentElement.clientHeight +
          wrapperRef.current.parentElement.parentElement.scrollTop <
        wrapperRef.current.parentElement.parentElement.scrollHeight - 200
      )
        return;
      setLoading(true);
    }

    wrapperRef.current.parentElement.parentElement.addEventListener(
      "scroll",
      handleScroll
    );

    return () => {
      if (wrapperRef.current)
        wrapperRef.current.parentElement.parentElement.removeEventListener(
          "scroll",
          handleScroll
        );
    };
  }, [loading]);

  // I left the wrapper instead of null so the white background doesn't appear if it's a dark theme
  if (!userInfo) return <Wrapper />;
  return (
    <Wrapper ref={wrapperRef}>
      <Container>
        <FeedWrapper>
          <FeedContainer>
            <StartDiscussion page="home" onPost={loadPosts} />

            {selectedPostImage && (
              <ImageCarousel
                onClosePressed={() => setSelectedPostImage(null)}
                images={selectedPostImage.post.images || []}
                initialSlide={selectedPostImage.imageIndex || 0}
              />
            )}

            {posts.map((post: PostType) => (
              <Post
                key={`post_${post._id}_${post.upVotes.length}_${post.downVotes.length}`}
                post={post}
                screen="home"
                userInfo={userInfo}
                onDelete={() => removePost(post._id)}
                onChange={async () => {
                  await modifyPost(post._id);
                }}
                onImageClick={(imageIndex) =>
                  setSelectedPostImage({ post, imageIndex })
                }
              />
            ))}
          </FeedContainer>
        </FeedWrapper>

        <ActivitiesContainer>
          <Activities />
        </ActivitiesContainer>
      </Container>
    </Wrapper>
  );
}
