/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { Route, Switch, useLocation } from "wouter";
import querystring from "query-string";
import { getLairBytId } from "../api/Lair";
import { getUserByUid } from "../api/User";
import lairAtom from "../atoms/LairAtom";
import userInfoAtom from "../atoms/UserInfoAtom";
import Auth from "../components/Auth/Auth";
import HomeLayout from "../layout/HomeLayout";
import HomeScreen from "../screens/HomeScreen";
import LairScreen from "../screens/LairScreen";
import ManageLairScreen from "../screens/ManageLairScreen";
import MessagesScreen from "../screens/MessagesScreen";
import ProfileScreen from "../screens/ProfileScreen";
import SettingScreen from "../screens/SettingsScreen";
import CreateAccountScreen from "../screens/CreateAccountScreen";
import Invite from "../screens/Invite";
import UnauthorizedScreen from "../screens/UnauthorizedScreen";

// const Scope = (props: any) => {
//   const router = useRouter();
//   const [parentLocation] = useLocation();

//   const { base, children } = props;

//   const nestedBase = `${router.base}${base}`;

//   // don't render anything outside of the scope
//   if (!parentLocation.startsWith(nestedBase)) return null;

//   // we need key to make sure the router will remount if the base changes
//   return (
//     <Router base={nestedBase} key={nestedBase}>
//       {children}
//     </Router>
//   );
// };

export default function Routers(): JSX.Element {
  const [location, setLocation] = useLocation();
  const setUserInfo = useSetRecoilState(userInfoAtom);
  const setLair = useSetRecoilState(lairAtom);

  useEffect(() => {
    const userUid = localStorage.getItem("@sociallair/uid");
    const lairId = localStorage.getItem("@sociallair/lairId");
    if (!userUid) {
      setLocation("/");
    } else {
      (async () => {
        const user = await getUserByUid(userUid);
        setUserInfo(user);

        if (lairId) {
          const lair = await getLairBytId(lairId);
          setLair(lair);
        }
      })();
    }
  }, []);

  useEffect(() => {
    // Don't let the guy go to login
    const userUid = localStorage.getItem("@sociallair/uid");
    if (location === "/" && userUid) setLocation("/home");
  }, [location]);

  return (
    <Switch>
      <Route path="/home/:content?">
        {() => (
          <HomeLayout>
            <HomeScreen />
          </HomeLayout>
        )}
      </Route>
      <Route path="/settings">
        {() => (
          <HomeLayout>
            <SettingScreen />
          </HomeLayout>
        )}
      </Route>
      <Route path="/unauthorized" component={UnauthorizedScreen} />

      <Route path="/invite">
        <HomeLayout>
          <Invite />
        </HomeLayout>
      </Route>
      <Route path="/register" component={CreateAccountScreen} />

      <Route path="/sl/:username/:content?" component={ProfileScreen} />
      <Route path="/messages" component={MessagesScreen} />

      <Route path="/lair/:lairname/:content?" component={LairScreen} />
      <Route path="/managelair" component={ManageLairScreen} />
      <Route
        path="/:page"
        component={(params) => Auth(params.params as { page: string })}
      />

      <Route path="/" component={() => Auth({ page: "login" })} />
    </Switch>
  );
}
