import { Theme } from "@emotion/react";
import { LogoLight } from "../../assets/img/logo-light";

const light: Theme = {
  title: "light",

  logo: LogoLight,

  colors: {
    title: "#2F2F2F",
    text: "#2F2F2F",
    header: "#FFFFFF",
    background: "#F9FAFA",
    background_aux: "#FFFFFF",
    border: "#dddfe2",

    sidebar: {
      hover: "#F6FAFB",
    },

    card: {
      background: "#FFFFFF",
      hover: "#F4F9FC",
      border: "#dddfe2",
    },

    search: {
      background: "#FFFFFF",
      text: "#B1BBC6",
    },
  },
};

export default light;
