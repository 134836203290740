import { css } from "@emotion/react";
import styled from "@emotion/styled";

const POST_WIDTH = 595 - 44;

/** We have multiple image grids, depending on the image */
interface ImgGridProps {
  count: number;
}

const ImgGrid = styled.div<ImgGridProps>`
  width: ${POST_WIDTH}px;
  height: 100%;

  display: grid;
  grid-column-gap: 5px;
  grid-row-gap: 5px;

  position: relative;

  ${(props) => {
    if (props.count === 2) {
      return css`
        grid-template-columns: 1fr 1fr;
        /* Both images fill one line so we divide the height by 2 to make them square */
        grid-template-rows: calc(${POST_WIDTH}px / 2);
      `;
    }

    if (props.count === 3) {
      return css`
        grid-template-columns: 1fr 1fr 1fr 1fr;
        /* The images on the right are half of the original */
        grid-template-rows: calc(${POST_WIDTH}px / 2 / 2) calc(
            ${POST_WIDTH}px / 2 / 2
          );

        .first {
          grid-column: 1/4;
          grid-row: 1/3;
        }
      `;
    }

    if (props.count >= 4) {
      return css`
        grid-template-columns: 1fr 1fr 1fr 1fr;
        /* The images on the right are are a third of the original */
        grid-template-rows:
          calc(${POST_WIDTH}px / 2 / 3) calc(${POST_WIDTH}px / 2 / 3)
          calc(${POST_WIDTH}px / 2 / 3);

        .first {
          grid-column: 1/4;
          grid-row: 1/4;
        }
      `;
    }

    return ``;
  }}
`;

export const ImageGridCounter = styled.div`
  width: 100%;
  height: 100%;

  position: absolute;

  top: 0px;
  left: 0px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #000000dd;

  color: #ffffff;

  grid-column: 4;
  grid-row: 3;
  border-radius: 5px;
  font-size: 32px;
`;

export default ImgGrid;
