/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-underscore-dangle */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import "pure-react-carousel/dist/react-carousel.es.css";
import React, { useEffect, useRef, useState } from "react";
import { addComment } from "../api/Comment";
import { addDownPost, addUpPost, deletePost } from "../api/Post";
import DownIcon from "../assets/icon/actions/down.svg";
import DownSelectedIcon from "../assets/icon/actions/downSelected.svg";
import UpIcon from "../assets/icon/actions/up.svg";
import UpSelectedIcon from "../assets/icon/actions/upSelected.svg";
import CommentIcon from "../assets/icon/comment.svg";
import ShareIcon from "../assets/icon/share.svg";
import ThreeDotsIcon from "../assets/icon/threedots.svg";
import dateFromObjectId from "../utils/DateFromObjectId";
import getImageUrl from "../utils/GetImageURL";
import { Flex } from "../utils/HelperStyles";
import { CommentType, PostType, User } from "../utils/Types";
import Comment from "./Comment";
import ImageCarousel from "./ImageCarousel";
import ImgGrid, { ImageGridCounter } from "./ImageGrid";
import StyledInput from "./StyledInput";

const Container: any = styled.div`
  ${Flex};
  flex-direction: column;
  border: 1px solid;
  background-color: ${(props: any) =>
    props.color === "secondary"
      ? props.theme.colors.background_aux
      : props.theme.colors.card.background};
  border-color: ${(props) => props.theme.colors.card.border};
  border-radius: 8px;
  padding: 22px;
  margin-bottom: 20px;
  width: 595px;
`;

const Heading = styled.div`
  width: 100%;
  ${Flex};
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 13px;
`;

const ProfileImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 40px;
  margin-right: 10px;
`;

const ProfileName = styled.span`
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.18px;
  color: ${(props) => props.theme.colors.text};
  margin-right: 8px;
  margin-top: 3px;
`;

const Schedule = styled.span`
  letter-spacing: 0px;
  color: #818a91;
  font-size: 14px;
  margin-top: 6px;
`;

const OpenPopover = styled.div`
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: relative;
`;

const Content = styled.span`
  width: 100%;
  margin-bottom: 18px;
  color: ${(props) => props.theme.colors.text};
  word-wrap: break-word;
  white-space: pre-line;
`;

const PopoverDiv = styled.div`
  position: absolute;
  top: 33px;
  right: -16px;

  z-index: 999;
`;

const Popover = styled.div`
  width: 203px;
  box-shadow: 0px 3px 8px #0000001f;
  border: 1px solid;
  background: ${(props) => props.theme.colors.background_aux};
  border-color: ${(props) => props.theme.colors.border};
  position: relative;
  border-radius: 4px;

  &::after {
    content: "";
    position: absolute;
    right: 14px;
    top: -8px;
    box-sizing: border-box;
    border: 8px solid #ededed;
    border-color: ${(props) =>
      `transparent transparent ${props.theme.colors.background_aux} ${props.theme.colors.background_aux}`};
    transform: rotate(135deg);
    box-shadow: ${(props) => `-1px 1px 1px 0 ${props.theme.colors.border}`};
  }
`;

const PopoverLine = styled.div`
  width: 200px;
  height: 40px;
  ${Flex};
  flex-direction: row;
  margin: 12px 18px;
`;

const PopoverIcon = styled.div`
  width: 16px;
  height: 16px;
  background-color: red;
`;

const PopoverText = styled.span`
  font-size: 15px;
  font-weight: medium;
  letter-spacing: 0.15px;
  color: ${(props) => props.theme.colors.text};
  margin-left: 10px;
`;

const Actions = styled.div`
  ${Flex};
  justify-content: space-between;
  width: 100%;
  padding: 10px 0px;
  border-top: solid 1px ${(props) => props.theme.colors.card.border};
  border-bottom: solid 1px ${(props) => props.theme.colors.card.border};
`;

const FlexDiv = styled.div`
  ${Flex};
`;

const ActionText = styled.div`
  margin-left: 7px;
  font-size: 14px;
  color: ${(props) => props.theme.colors.text};
  cursor: default;
`;

const SelectIcon = styled.img`
  width: 15px;
  height: 15px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const ActionButton = styled.div`
  ${Flex};
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const DefaultIcon = styled.img`
  width: 15px;
  height: 15px;
`;

const Img = styled.img`
  max-width: 100%;
  min-width: 100%;

  height: 100%;
  max-height: 634px;
  object-fit: cover;
  background-color: #00000077;

  border-radius: 5px;
`;

const NO_LIKE = 0;
const LIKE = 1;
const DISLIKE = 2;

interface Props {
  color?: string;
  post: PostType;
  screen: string;
  userInfo: User;
  onChange: () => void;
  onDelete: () => void;
  onImageClick: (imageIndex: number) => void;
}

export default function Post(props: Props): JSX.Element {
  const {
    color,
    post,
    screen,
    userInfo,
    onChange,
    onDelete,
    onImageClick,
  } = props;

  const [popover, showPopover] = useState(false);
  const [action, setAction] = useState(NO_LIKE);
  const commentRef = useRef<any>(null);

  const [isPostingComment, setIsPostingComment] = useState(false);

  useEffect(() => {
    if (post.upVotes.indexOf(userInfo.uid) > -1) setAction(LIKE);
    else if (post.downVotes.indexOf(userInfo.uid) > -1) setAction(DISLIKE);
    else setAction(NO_LIKE);
  }, []);

  async function makeCommentOnPost() {
    setIsPostingComment(true);
    const success = await addComment({
      content: commentRef.current?.value,
      author: userInfo.uid,
      postId: post._id,
    });
    if (success) {
      commentRef.current.value = "";
      onChange();
    }
    setIsPostingComment(false);
  }

  return (
    <Container color={color} screen={screen}>
      <Heading>
        <FlexDiv
          css={css`
            flex-direction: row;
          `}
        >
          {post.authorInfo[0] && (
            <ProfileImage src={getImageUrl(post.authorInfo[0].image)} />
          )}
          {post.authorInfo[0] && (
            <ProfileName> {post.authorInfo[0].name}</ProfileName>
          )}
          <Schedule> {dateFromObjectId(post._id)}</Schedule>
        </FlexDiv>
        {post.author === userInfo.uid && (
          <OpenPopover
            onClick={() => {
              showPopover(!popover);
            }}
          >
            <DefaultIcon src={ThreeDotsIcon} />
            {popover && (
              <PopoverDiv>
                <Popover>
                  <PopoverLine>
                    <PopoverIcon />
                    <PopoverText
                      style={{ color: "#C00000" }}
                      onClick={async () => {
                        const success = await deletePost(post._id);
                        if (success) onDelete();
                      }}
                    >
                      Delete post
                    </PopoverText>
                  </PopoverLine>
                  {/* <PopoverLine>
                  <PopoverIcon />
                  <PopoverText>Hide post</PopoverText>
                </PopoverLine>
                <PopoverLine>
                  <PopoverIcon />
                  <PopoverText style={{ color: "#C00000" }}>
                    Report post
                  </PopoverText>
                </PopoverLine> */}
                </Popover>
              </PopoverDiv>
            )}
          </OpenPopover>
        )}
      </Heading>

      <Content>{post.content}</Content>

      {post.images && post.images.length > 0 && (
        <ImgGrid count={post.images.length}>
          {post.images.map((image: string, index) => {
            if (index >= 4) return null;
            return (
              <Img
                className={index === 0 ? "first" : ""}
                key={image}
                src={getImageUrl(image)}
                onClick={() => onImageClick(index)}
              />
            );
          })}
          {post.images.length > 4 && (
            <ImageGridCounter
              onClick={() => onImageClick(3)}
              className="noselect"
            >
              + {post.images.length}
            </ImageGridCounter>
          )}
        </ImgGrid>
      )}

      <Actions style={{ marginTop: 10 }}>
        <FlexDiv>
          <FlexDiv css={css``}>
            <SelectIcon
              src={action !== LIKE ? UpIcon : UpSelectedIcon}
              onClick={async () => {
                if (action !== LIKE) {
                  const likedResponse = await addUpPost(post._id, userInfo.uid);
                  if (!likedResponse) return;
                  setAction(LIKE);
                  onChange();
                }
              }}
            />
            <ActionText>{post.upVotes.length}</ActionText>
          </FlexDiv>
          <FlexDiv
            css={css`
              margin-left: 18px;
            `}
          >
            <SelectIcon
              src={action !== DISLIKE ? DownIcon : DownSelectedIcon}
              onClick={async () => {
                if (action !== DISLIKE) {
                  const unlikedResponse = await addDownPost(
                    post._id,
                    userInfo.uid
                  );
                  if (!unlikedResponse) return;
                  setAction(DISLIKE);
                  onChange();
                }
              }}
            />
            <ActionText>{post.downVotes.length}</ActionText>
          </FlexDiv>
          <FlexDiv
            css={css`
              margin-left: 18px;
            `}
          >
            <DefaultIcon src={CommentIcon} />
            <ActionText>
              {Object.keys(post.comments[0]).length === 0
                ? 0
                : post.comments.length}
            </ActionText>
          </FlexDiv>
          <FlexDiv
            css={css`
              margin-left: 18px;
            `}
          >
            <DefaultIcon src={ShareIcon} />
            <ActionText>0</ActionText>
          </FlexDiv>
        </FlexDiv>
        <FlexDiv>
          <ActionButton>
            <DefaultIcon src={CommentIcon} />
            <ActionText
              css={css`
                cursor: pointer;
              `}
            >
              Comment
            </ActionText>
          </ActionButton>
          <ActionButton
            css={css`
              margin-left: 18px;
            `}
          >
            <DefaultIcon src={ShareIcon} />
            <ActionText
              css={css`
                cursor: pointer;
              `}
            >
              Share
            </ActionText>
          </ActionButton>
        </FlexDiv>
      </Actions>
      <FlexDiv
        css={css`
          width: 100%;
          flex-direction: column;
        `}
      >
        {post.comments.map((comment: CommentType) =>
          Object.keys(comment).length === 0 ? null : (
            <Comment
              comment={comment}
              key={comment.id}
              userUid={userInfo.uid}
              postId={post._id}
              onChange={onChange}
            />
          )
        )}
      </FlexDiv>
      <FlexDiv
        css={css`
          width: 100%;
          margin-top: 20px;
        `}
      >
        <ProfileImage
          src={getImageUrl(userInfo.image)}
          css={css`
            width: 32px;
            height: 32px;
            border-radius: 32px;
          `}
        />
        <StyledInput
          disabled={isPostingComment}
          ref={commentRef}
          style={{ height: "40px" }}
          placeholder="Write a comment…"
          // IconSrc={MoreIcon}
          color={color}
          onEnterPressed={() => {
            makeCommentOnPost();
          }}
        />
      </FlexDiv>
    </Container>
  );
}

Post.defaultProps = {
  color: "primary",
};
