import React from "react";
import styled from "@emotion/styled";
import Button from "../StyledButton";
import { Container, Subtext, Title } from "./EditProfile";
import InputSetting from "../InputSetting";

const LinkText = styled.span`
  font-size: 16px;
  font-weight: medium;
  letter-spacing: 0.16px;
  color: #0075bb;
  align-self: flex-end;
  cursor: pointer;
  margin: 0px 43px 30px 0px;
`;

export default function ChangePassword(): JSX.Element {
  return (
    <Container>
      <Title>Change Your Password</Title>
      <Subtext style={{ marginBottom: "30px" }}>
        Use a strong password that you’re not using elsewhere
      </Subtext>

      <InputSetting
        onChange={() => {
          console.log("alou");
        }}
      >
        Old Password
      </InputSetting>

      <InputSetting
        onChange={() => {
          console.log("alou");
        }}
      >
        New Password
      </InputSetting>

      <InputSetting
        style={{ marginBottom: "20px" }}
        onChange={() => {
          console.log("alou");
        }}
      >
        New Password
      </InputSetting>

      <LinkText>Forgot Password?</LinkText>
      <Button
        inverted
        style={{
          alignSelf: "flex-end",
          margin: "0px 43px 49px 0px",
          width: "180px",
          height: "46px",
        }}
      >
        Change Password
      </Button>
    </Container>
  );
}
