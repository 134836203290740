import React from "react";
import { useLocation } from "wouter";
import { Global, ThemeProvider } from "@emotion/react";
import { useRecoilValue } from "recoil";
import Header from "./components/Header/Header";
import GlobalStyle from "./styles/global";
import light from "./styles/themes/light";
import dark from "./styles/themes/dark";
import Modal from "./components/Modal/Modal";
import Routers from "./routes/Router";
import { Theme } from "./utils/Types";
import userInfoAtom from "./atoms/UserInfoAtom";

function App(): JSX.Element {
  const [location] = useLocation();
  const userInfo = useRecoilValue(userInfoAtom);

  return (
    <ThemeProvider theme={userInfo?.theme === Theme.light ? light : dark}>
      <Global styles={GlobalStyle} />
      <Modal />
      {location !== "/" && <Header />}
      <Routers />
    </ThemeProvider>
  );
}

export default App;
