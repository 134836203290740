import styled from "@emotion/styled";
import React, { DetailedHTMLProps, HTMLAttributes } from "react";
import { Flex } from "../utils/HelperStyles";

interface Props
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  check: boolean;
  handleCheck: () => void;
}

const Container = styled.div`
  ${Flex};
  flex-direction: row;
`;

const Text = styled.div`
  font-size: 15px;
  letter-spacing: 0.15px;
  color: ${(props) => props.theme.colors.text};
  margin-left: 8px;
`;

export default function StyledCheckBox(props: Props): JSX.Element {
  const { check, handleCheck, children } = props;
  return (
    <Container {...props}>
      <input type="checkbox" defaultChecked={check} onChange={handleCheck} />
      <Text> {children}</Text>
    </Container>
  );
}
