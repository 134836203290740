/* eslint-disable @typescript-eslint/ban-ts-comment */

// import {} from "react-dom/experimental";
import React from "react";
import ReactDOM from "react-dom";
import { RecoilRoot } from "recoil";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const rootEl = document.getElementById("root") as HTMLElement;

// Note that you can ommit the StrictMode
// @ts-ignore
ReactDOM.unstable_createRoot(rootEl).render(
  <React.StrictMode>
    <RecoilRoot>
      <App />
    </RecoilRoot>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
