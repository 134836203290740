import type countriesData from "./countries.json";

export interface IconType {
  iconSVG: string;
}

export enum Theme {
  dark = "dark",
  light = "light",
}

export enum PostStatus {
  approved = "approved",
  pending = "pending",
  refused = "refused",
}

export interface LairType {
  _id: string;
  name: string;
  lairname: string;
  description: string;
  admin: string;
  image: string;
  coverImage: string;
  users: User[];
  approvePosts: boolean;
  privacy: boolean;
  location: string;
}

export interface User {
  uid: string;
  name: string;
  username: string;
  description: string;
  email: string;
  phone: string;
  connections: string[];
  image: string;
  coverImage: string;
  theme: Theme;
  lairs: LairType[];
}

export type RefUtil<T> =
  | ((instance: T | null) => void)
  | React.MutableRefObject<T | null>
  | null;

export interface PostType {
  _id: string;
  content: string;
  author: string;
  lair: string | null;
  status: PostStatus;
  upVotes: string[];
  downVotes: string[];
  images: string[];
  authorInfo: User[];
  comments: CommentType[];
}

export interface CommentType {
  id: string;
  content: string;
  author: string;
  authorInfo: User;
  upVotes: string[];
  downVotes: string[];
}

export type Country = typeof countriesData[0];

// Helper
export const NO_COUNTRY = null;
