/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-underscore-dangle */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { addDownComment, addUpComment } from "../api/Comment";
import DownIcon from "../assets/icon/actions/down.svg";
import DownSelectedIcon from "../assets/icon/actions/downSelected.svg";
import UpIcon from "../assets/icon/actions/up.svg";
import UpSelectedIcon from "../assets/icon/actions/upSelected.svg";
import getImageUrl from "../utils/GetImageURL";
import { Flex } from "../utils/HelperStyles";
import { CommentType } from "../utils/Types";

const Container = styled.div`
  width: 100%;
  margin: 10px 0px;
`;

const FlexDiv = styled.div`
  ${Flex};
`;

const Profile = styled.div`
  display: grid;
  grid-template-areas:
    "image name "
    "image content ";
  grid-template-columns: 32px auto;
  column-gap: 15px;
`;

const ProfileImage = styled.img`
  grid-area: image;
  width: 32px;
  height: 32px;
  border-radius: 32px;
`;

const ProfileName = styled.span`
  grid-area: name;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0px;
  color: ${(props) => props.theme.colors.text};
  margin-bottom: 3px;
`;

const Content = styled.span`
  grid-area: content;
  font-size: 16px;
  letter-spacing: 0.16px;
  color: ${(props) => props.theme.colors.text};
  word-wrap: break-word;
`;

const Actions = styled.div`
  ${Flex};
  justify-content: space-between;
  width: 100%;
  padding: 8px 50px;
`;

const ActionText = styled.div`
  margin-left: 7px;
  font-size: 12px;
  color: ${(props) => props.theme.colors.text};
  cursor: default;
`;

const SelectIcon = styled.img`
  width: 12px;
  height: 12px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const NO_LIKE = 0;
const LIKE = 1;
const DISLIKE = 2;

interface Props {
  comment: CommentType;
  userUid: string;
  postId: string;
  onChange: () => void;
}

export default function Comment(props: Props): JSX.Element {
  const [action, setAction] = useState(NO_LIKE);
  const { comment, userUid, postId, onChange } = props;

  useEffect(() => {
    if (comment.upVotes.indexOf(userUid) > -1) setAction(LIKE);
    else if (comment.downVotes.indexOf(userUid) > -1) setAction(DISLIKE);
    else setAction(NO_LIKE);
  }, []);

  return (
    <Container>
      <FlexDiv>
        <Profile>
          <ProfileImage src={getImageUrl(comment.authorInfo?.image)} />
          <ProfileName
            style={comment.authorInfo ? {} : { fontStyle: "italic" }}
          >
            {comment.authorInfo?.name || "Deleted User"}
          </ProfileName>
          <Content>{comment.content}</Content>
        </Profile>
      </FlexDiv>

      <Actions>
        <FlexDiv>
          <FlexDiv css={css``}>
            <SelectIcon
              src={action !== LIKE ? UpIcon : UpSelectedIcon}
              onClick={async () => {
                if (action !== LIKE) {
                  const success = await addUpComment(
                    comment.id,
                    postId,
                    userUid
                  );
                  if (!success) return;
                  setAction(LIKE);
                  onChange();
                }
              }}
            />
            <ActionText>{comment.upVotes.length}</ActionText>
          </FlexDiv>
          <FlexDiv
            css={css`
              margin-left: 18px;
            `}
          >
            <SelectIcon
              src={action !== DISLIKE ? DownIcon : DownSelectedIcon}
              onClick={async () => {
                if (action !== DISLIKE) {
                  const success = await addDownComment(
                    comment.id,
                    postId,
                    userUid
                  );
                  if (!success) return;
                  setAction(DISLIKE);
                  onChange();
                }
              }}
            />
            <ActionText>{comment.downVotes.length}</ActionText>
          </FlexDiv>
        </FlexDiv>
      </Actions>
    </Container>
  );
}
