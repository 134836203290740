/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
/** @jsxImportSource @emotion/react */
import React, { useRef, useState } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import TextareaAutosize from "react-textarea-autosize";
import { nanoid } from "nanoid";
import Button from "../../StyledButton";
import TagIcon from "../../../assets/icon/tag.svg";
import PhotoIcon from "../../../assets/icon/photo.svg";
import CloseIcon from "../../../assets/icon/close-new.svg";
import { BUCKET_URL } from "../../../utils/Url";
import { User } from "../../../utils/Types";
import readFile from "../../../utils/ReadFile";
import b64toBlob from "../../../utils/B64toBlob";
import { addPost } from "../../../api/Post";
import { Flex } from "../../../utils/HelperStyles";
import getImageUrl from "../../../utils/GetImageURL";
import ImgGrid, { ImageGridCounter } from "../../ImageGrid";
import ImageCarousel from "../../ImageCarousel";

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${(props) => props.theme.colors.card.border};
  box-sizing: inherit;
  margin: 10px -12px 0px;
  width: 600px;
`;

const Buttons = styled.div`
  ${Flex};
  width: 100%;
  justify-content: space-between;
  border-top: 1px solid ${(props) => props.theme.colors.card.border};
  padding: 20px 20px 0px;
`;

const InputLabel = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding: 0px 20px;
`;

const TextArea = styled(TextareaAutosize)`
  width: 100%;
  font-size: 14px;
  padding: 5px;
  color: ${(props) => props.theme.colors.title};
  border: none;
  resize: none;
  height: 120px;
  padding: 15px 15px 5px 15px;

  &::placeholder {
    font-size: 14px;
    letter-spacing: 0.16px;
    color: #b1bbc6;
  }
  background-color: ${(props) => props.theme.colors.card.background};
`;

const ProfileName = styled.div`
  color: ${(props) => props.theme.colors.title};
  font-weight: 600;
  margin-left: 5px;
  font-size: 15px;
`;

const FlexDiv = styled.div`
  ${Flex};
`;

const ProfileImage = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 30px;
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;
`;

const IconDiv = styled.div`
  position: absolute;
  right: 30px;
  top: 5px;
  width: 35px;
  height: 35px;
  border-radius: 35px;
  background-color: #77797c;
  opacity: 0.9;
  z-index: 100;
  ${Flex}
  justify-content: center;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

const Label = styled.div`
  color: #818a91;
  font-size: 12px;
  margin-left: 5px;
`;

const Img = styled.img`
  max-width: 100%;
  min-width: 100%;

  height: 100%;
  max-height: 634px;
  object-fit: cover;
  background-color: #00000077;
  border-radius: 5px;
`;
const Images = styled.div`
  ${Flex};
  justify-content: center;
  position: relative;
  margin-bottom: 10px;
`;

const Content = styled.div`
  max-height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 10px;
`;

interface Props {
  onPost: () => void;
  closeModal: () => void;
  lair: any;
  userInfo: User;
}

interface ImagesObj {
  blob: Blob;
  uuid: string;
  url: string;
}

export default function CreatePost(props: Props): JSX.Element | null {
  const { closeModal, onPost, userInfo, lair } = props;
  const textAreaRef = useRef<any>(null);
  const imageRef = useRef<HTMLInputElement>(null);
  const [images, setImages] = useState<ImagesObj[]>([]);
  const [isLoading, setLoading] = useState(false);

  async function onFileChange(e: any) {
    const files = [...e.target.files];
    const arrayImages = await Promise.all(
      files.map(async (file) => {
        const imageDataUrl: string = await readFile(file);
        const imageBlob = b64toBlob(imageDataUrl);
        return {
          blob: imageBlob,
          url: URL.createObjectURL(imageBlob),
          uuid: nanoid(),
        };
      })
    );
    setImages([...images, ...arrayImages]);
  }

  if (!userInfo) return null;
  return (
    <ModalBody>
      <InputLabel>
        <FlexDiv>
          <ProfileImage src={getImageUrl(userInfo.image)} />
          <ProfileName>{userInfo.name}</ProfileName>
        </FlexDiv>
      </InputLabel>
      <Content>
        <TextArea
          placeholder={`What do you want to discuss, ${userInfo.name}`}
          ref={textAreaRef}
          minRows={5}
        />

        <Images>
          <IconDiv
            css={css`
              display: ${images.length < 1 && "none"};
            `}
          >
            <Icon
              src={CloseIcon}
              onClick={() => {
                setImages([]);
              }}
            />
          </IconDiv>
          {images && images.length > 0 && (
            <ImgGrid count={images.length}>
              {images.map((image: ImagesObj, index) => {
                if (index >= 4) return null;
                return (
                  <Img
                    className={index === 0 ? "first" : ""}
                    key={image.uuid}
                    src={image.url}
                    onClick={() => console.log("clicou ")}
                  />
                );
              })}
              {images.length > 4 && (
                <ImageGridCounter
                  onClick={() => console.log("clicou ")}
                  className="noselect"
                >
                  + {images.length}
                </ImageGridCounter>
              )}
            </ImgGrid>
          )}
        </Images>
      </Content>

      <Buttons>
        <FlexDiv>
          <FlexDiv
            css={css`
              cursor: pointer;
            `}
            onClick={() => imageRef.current?.click()}
          >
            <input
              ref={imageRef}
              type="file"
              multiple
              accept="image/*"
              onChange={onFileChange}
              style={{ display: "none" }}
            />
            <Icon src={PhotoIcon} />
            <Label>Add Photo</Label>
          </FlexDiv>
          <FlexDiv
            css={css`
              margin-left: 15px;
              cursor: pointer;
            `}
          >
            <Icon src={TagIcon} />
            <Label>Tag Friends</Label>
          </FlexDiv>
        </FlexDiv>
        <Button
          inverted
          isLoading={isLoading}
          css={css`
            width: 80px;
          `}
          onClick={async () => {
            setLoading(true);
            if (userInfo) {
              const postSaved = await addPost(
                textAreaRef.current?.value,
                userInfo.uid,
                lair,
                images.map((image) => image.blob)
              );
              if (!postSaved) return;
              onPost();
              closeModal();
            }
          }}
        >
          Post
        </Button>
      </Buttons>
    </ModalBody>
  );
}
