/* eslint-disable react/require-default-props */
import React, {
  DetailedHTMLProps,
  forwardRef,
  InputHTMLAttributes,
} from "react";
import styled from "@emotion/styled";
import { RefUtil } from "../utils/Types";

const Search = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
`;

const Input = styled.input`
  width: 100%;
  height: 100%;
  border: 1px solid;
  border-color: ${(props) =>
    props.color === "secondary"
      ? props.theme.colors.border
      : props.theme.colors.card.border};
  border-radius: 5px;
  text-indent: 10px;
  color: ${(props) => props.theme.colors.text};
  background-color: ${(props) =>
    props.color === "secondary"
      ? props.theme.colors.background_aux
      : props.theme.colors.card.background};

  &::placeholder {
    font: normal normal normal 16px/21px;
    letter-spacing: 0.16px;
    color: ${(props) => props.theme.colors.search.text};
  }

  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;

const Icon = styled.img`
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  width: 20px; /* Need a specific value to work */
`;

interface Props
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  IconSrc?: string;
  color?: string;
  onEnterPressed?: () => void;
  disabled?: HTMLInputElement["disabled"];
}

function StyledInput(
  props: Props,
  ref: RefUtil<HTMLInputElement>
): JSX.Element {
  const {
    placeholder,
    IconSrc,
    color,
    onChange,
    onEnterPressed,
    disabled,
  } = props;

  return (
    <Search {...props}>
      <Input
        placeholder={placeholder}
        disabled={disabled}
        color={color}
        ref={ref}
        onChange={onChange}
        onKeyDown={(evt) => {
          if (evt.key === "Enter" && onEnterPressed) onEnterPressed();
        }}
      />
      {IconSrc && <Icon src={IconSrc} alt="Search Icon" />}
    </Search>
  );
}

export default forwardRef<HTMLInputElement, Props>(StyledInput);
