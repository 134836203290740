import React from "react";
import styled from "@emotion/styled";
import { useRecoilState } from "recoil";

import Button from "../StyledButton";
import Checkbox from "../StyledCheckbox";
import { Container, Subtext, Text, Title } from "./EditProfile";
import { Theme } from "../../utils/Types";
import { updateUser } from "../../api/User";
import userInfoAtom from "../../atoms/UserInfoAtom";
import { Flex } from "../../utils/HelperStyles";

const Select = styled.select`
  width: 614px;
  height: 46px;
  margin: 0px 43px 40px 43px;
  font-size: 17px;
  letter-spacing: 0.17px;
  color: ${(props) => props.theme.colors.text};
  background-color: ${(props) => props.theme.colors.background_aux};
`;

const Line = styled.div`
  ${Flex};
  flex-direction: row;
  margin: 20px 0px 0px 43px;
  color: ${(props) => props.theme.colors.text};
`;

const Radio = styled.input`
  width: 24px;
  height: 24px;
  margin-right: 15px;
`;

export default function Preferences(): JSX.Element {
  const [userInfo, setUserInfo] = useRecoilState<any>(userInfoAtom);

  return (
    <Container>
      <Title>Your Preferences</Title>

      <Text style={{ marginTop: "28px" }}>Appearance</Text>
      <Subtext>Change the appearance of Social Lair</Subtext>

      <Line>
        <Radio
          type="radio"
          name="theme"
          defaultChecked={userInfo.theme === Theme.light}
          onClick={() => {
            setUserInfo({ ...userInfo, theme: Theme.light });
          }}
        />
        Light Mode
        <Radio
          type="radio"
          name="theme"
          defaultChecked={userInfo.theme === Theme.dark}
          style={{ marginLeft: "38px" }}
          onClick={() => {
            setUserInfo({ ...userInfo, theme: Theme.dark });
          }}
        />
        Dark Mode
      </Line>

      <Text style={{ marginTop: "77px" }}>Time zone</Text>

      <Checkbox
        style={{ margin: "18px 0px 31px 43px" }}
        check
        handleCheck={() => {
          console.log("clicou");
        }}
      >
        Set time zone automatically
      </Checkbox>

      <Select>
        <option value="valor1">Valor 1</option>
        <option value="valor2" selected>
          Valor 2
        </option>
        <option value="valor3">Valor 3</option>
      </Select>

      <Button
        inverted
        style={{ margin: "0px 43px 58px 0px", alignSelf: "flex-end" }}
        onClick={async () => {
          const success = await updateUser({
            theme: userInfo.theme,
            uid: userInfo.uid,
          });
          if (success) console.log("Aee papi");
        }}
      >
        Save
      </Button>
    </Container>
  );
}
