/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-underscore-dangle */
/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import Post from "../Post";
import { LairType, PostType, User } from "../../utils/Types";
import { getLairApprovedPost, getPost } from "../../api/Post";
import { Flex } from "../../utils/HelperStyles";
import StartDiscussion from "../StarDiscussion";
import ImageCarousel from "../ImageCarousel";

const Wrapper = styled.div`
  ${Flex};
  flex-direction: column;
  height: fit-content;
  cursor: default;
  width: 100%;
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 650px 290px;
`;

const FeedContainer = styled.div`
  ${Flex};
  flex-direction: column;
  width: 600px;
`;

const FeedWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const RightContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const RightContent: any = styled.div`
  width: 100%;
  height: 190px;
  background-color: ${(props: any) => props.theme.colors.background_aux};
  border: 1px solid ${(props) => props.theme.colors.card.border};
  border-radius: 8px;
`;

const ContentTitle = styled.div`
  width: 100%;
  height: 45px;
  color: ${(props) => props.theme.colors.text};
  border-bottom: 1px solid ${(props) => props.theme.colors.card.border};
  letter-spacing: 0.17px;
  font-size: 17px;
  font-weight: 600;
  padding: 12px 20px 12px 20px;
`;

const ContentText = styled.div`
  letter-spacing: 0.17px;
  color: ${(props) => props.theme.colors.text};
  font-style: 17px;
  font-weight: medium;
  padding: 15px 0px 5px 45px;
`;

const PrivacyText = styled.div`
  letter-spacing: 0.15px;
  color: #818a91;
  font-size: 15px;
  padding: 0px 16px 0px 45px;
`;

interface Props {
  verify: boolean;
  userInfo: User;
  lair: LairType;
}

export default function LairActivity(props: Props): JSX.Element | null {
  const { verify, userInfo, lair } = props;
  const [posts, setPosts] = useState<PostType[]>([]);
  const [selectedPostImage, setSelectedPostImage] = useState<{
    post: PostType;
    imageIndex: number;
  } | null>(null);

  async function loadPosts() {
    const postResponse = await getLairApprovedPost(lair._id);
    if (postResponse) setPosts(postResponse);
  }

  async function modifyPost(postId: string) {
    const updatedPost = await getPost(postId);
    const updatedPosts: PostType[] = posts.map((postAux: PostType) => {
      if (postAux._id === updatedPost._id) return updatedPost;
      return postAux;
    });
    setPosts(updatedPosts);
  }

  useEffect(() => {
    (async () => {
      await loadPosts();
    })();
  }, [lair]);

  return (
    <Wrapper>
      <Container>
        <FeedWrapper>
          {selectedPostImage && (
            <ImageCarousel
              onClosePressed={() => setSelectedPostImage(null)}
              images={selectedPostImage.post.images || []}
              initialSlide={selectedPostImage.imageIndex || 0}
            />
          )}
          <FeedContainer>
            {verify && (
              <StartDiscussion
                page="lair"
                onPost={() => !lair.approvePosts && loadPosts()}
                color="secondary"
              />
            )}
            {posts.map((post: PostType) => (
              <Post
                color="secondary"
                key={post._id}
                post={post}
                screen="profile"
                userInfo={userInfo}
                onDelete={loadPosts}
                onChange={async () => {
                  await modifyPost(post._id);
                }}
                onImageClick={(imageIndex) =>
                  setSelectedPostImage({ post, imageIndex })
                }
              />
            ))}
          </FeedContainer>
        </FeedWrapper>

        <RightContainer>
          <RightContent>
            <ContentTitle>About</ContentTitle>
            <ContentText>
              {lair.privacy ? "Private Lair" : "Public Lair"}
            </ContentText>
            <PrivacyText>
              {lair.privacy
                ? "Only members can see the activities in this lair"
                : "Any user can see the activities in this lair"}
            </PrivacyText>
            <ContentText>{lair.location}</ContentText>
          </RightContent>
        </RightContainer>
      </Container>
    </Wrapper>
  );
}
