/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useRoute } from "wouter";
import { useRecoilState, useRecoilValue } from "recoil";
import { Flex } from "../utils/HelperStyles";
import Lair from "../components/Lair/Lair";
import { getLairByLairname } from "../api/Lair";
import lairAtom from "../atoms/LairAtom";
import userInfoAtom from "../atoms/UserInfoAtom";
import MembersList from "../components/Lair/MembersList";
import LairActivity from "../components/Lair/LairActivity";
import LairSidebar from "../components/Lair/LairSideBar";
import { LairType } from "../utils/Types";

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colors.background};
  height: 100vh;
  overflow: scroll;
  padding-top: 50px;
  display: grid;
  grid-template-columns: 221px auto;
  color: ${(props) => props.theme.colors.text};
`;

const Container = styled.div`
  position: relative;
  ${Flex};
  flex-direction: column;
`;

const Content = styled.div`
  margin: 30px 0px;
  width: 940px;
  border-radius: 4px;
`;

export default function LairScreen(): JSX.Element {
  const [, paramsWithContent] = useRoute<any>("/lair/:lairname/:content");
  const [, params] = useRoute<any>("/lair/:lairname");
  const [lair, setLair] = useRecoilState(lairAtom);
  const userInfo = useRecoilValue(userInfoAtom);
  const [content, setContent] = useState("");
  const [verify, setVerify] = useState(false);

  async function initLairScreen(lairname: string) {
    const lairAux = await getLairByLairname(lairname);
    setLair(lairAux);
    lairAux && localStorage.setItem("@sociallair/lairId", lairAux._id);
  }

  useEffect(() => {
    if (userInfo)
      (async () => {
        if (paramsWithContent && paramsWithContent.lairname) {
          paramsWithContent.lairname !== lair?.lairname && // Check if you are still on the same lair so you don't have to make another request
            (await initLairScreen(paramsWithContent.lairname));
          setContent(paramsWithContent.content);
        } else {
          params.lairname !== lair?.lairname &&
            (await initLairScreen(params.lairname));
          setContent("");
        }
      })();
  }, [params?.lairname, paramsWithContent?.content, userInfo]);

  useEffect(() => {
    if (!userInfo || !lair) return;
    const verifyCheck = userInfo.lairs.filter(
      (element) => element._id === lair._id
    );
    if (verifyCheck.length > 0) setVerify(true);
    else setVerify(false);
  }, [lair, userInfo]);

  // I left the wrapper instead of null so the white background doesn't appear if it's a dark theme
  if (!lair || !userInfo) return <Wrapper />;
  return (
    <Wrapper>
      <LairSidebar />
      <Container>
        <Lair lair={lair} verify={verify} />
        {/* Checks the type of privacy and whether the user belongs to the lair */}
        {(!lair.privacy || verify) && (
          <Content>
            {content === "" && (
              <LairActivity verify={verify} userInfo={userInfo} lair={lair} />
            )}
            {content === "members" && (
              <MembersList
                members={lair.users}
                admin={lair.admin === userInfo.uid}
              />
            )}
          </Content>
        )}
      </Container>
    </Wrapper>
  );
}
