import React, { useEffect, useMemo } from "react";
import styled from "@emotion/styled";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import querystring from "query-string";
import OverviewImage from "../../assets/img/left-login.svg";
import PhoneInput from "./PhoneInput";
import PasswordInput from "./PasswordInput";
import { inviteUUIDAtom } from "../../atoms/InviteUIDAtom";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: calc(50vh - 290px);
  padding-left: calc(50vw - 412.5px);
  @media screen and (max-width: 600px) {
    overflow-x: hidden;
    padding-top: 0;
    padding-left: 0;
  }
`;

const Container = styled.div`
  width: 825px;
  height: 580px;

  display: grid;
  grid-template-columns: 375px 450px;

  @media screen and (max-width: 600px) {
    display: flex;
    justify-content: center;
    border: none;
  }

  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e4e6e7;
`;

const StyledImage = styled.img`
  width: 364px;
  height: 580px;
  @media screen and (max-width: 600px) {
    display: none;
  }
`;

interface IAuth {
  page: string | undefined;
}

export default function Auth(props: IAuth): JSX.Element {
  const { page } = props;

  const [inviteUUID, setInviteUUID] = useRecoilState(inviteUUIDAtom);

  console.log({ recoilInviteUUID: inviteUUID });

  useEffect(() => {
    const query = querystring.parse(window.location.search) as {
      inviteUUID: string;
    } | null;

    console.log({ query });

    if (!query || !query.inviteUUID) return;
    // sessionStorage.setItem("inviteUID", query.inviteUUID);
    setInviteUUID(query.inviteUUID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectedPage = useMemo(() => {
    switch (page) {
      case "password":
        return <PasswordInput />;
      default:
        return <PhoneInput />;
    }
  }, [page]);

  return (
    <Wrapper>
      <Container>
        <StyledImage src={OverviewImage} alt="left-img" />
        {selectedPage}
      </Container>
    </Wrapper>
  );
}
