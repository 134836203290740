/* eslint-disable react/require-default-props */
import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "@emotion/styled";
import Fuse from "fuse.js";
import { Country, NO_COUNTRY } from "../../utils/Types";
import countries from "../../utils/countries.json";
import InputContainer from "./StyledInput";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  z-index: 20;
`;

const Flag = styled.img`
  height: 40px;
  width: 40px;
`;

const DropView = styled.div`
  position: absolute;
  background: #fff;
  z-index: 10;
  top: 40px;
  max-height: 220px;
  width: 100%;
  flex: 1;
  overflow: auto;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border: ${(props: { showBorder: boolean }) =>
    props.showBorder ? "solid 1px #cccccc" : "none"};
`;

interface DropItemProps {
  preSelected: boolean;
}

const DropItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 10px;
  cursor: pointer;

  background-color: ${(props: DropItemProps) =>
    props.preSelected ? "#efefef" : "#fff"};
`;

const CountryName = styled.div`
  font-size: 18px;
  padding: 10px;
`;

interface ICountryFinderInput {
  countryCode: string;
  setCountryCode: (code: string) => void;
  handleChangeCountry?: (
    country: Country | null,
    options?: { force: boolean }
  ) => void;
}

const fuse = new Fuse(countries, {
  keys: ["text"],
});

let debouncer: number;

export default function CountryFinderInput(
  props: ICountryFinderInput
): JSX.Element {
  const { setCountryCode, countryCode, handleChangeCountry } = props;
  const [selectedCountry, setSelectedCountry] = useState<
    Country | typeof NO_COUNTRY
  >(() => countries.find((c) => c.key === "us") as Country);

  const [filterCountryName, setFilterCountryName] = useState<string>("");
  const [openDropView, setOpenDropView] = useState<boolean>(false);
  const [preSelectedCountryKey, setPreSelectedCountryKey] = useState<
    Country["key"] | typeof NO_COUNTRY
  >(NO_COUNTRY);

  // Filter countries by name
  // EXPERIMENTAL, adding fuzzy search to country
  const filteredCountries = useMemo(
    () => countries,
    // EXPERIMENTAL, adding fuzzy search to country
    // countries.filter((countryFilter) =>
    //   countryFilter.text
    //     .toUpperCase()
    //     .includes(filterCountryName.toUpperCase())
    // )
    []
  );

  // Ref so we're able to scroll the items into view
  const dropItemRefs = useRef<
    {
      countryKey: string;
      element: HTMLDivElement | null;
    }[]
  >([]);

  // Scroll drop view to the pre-selected country
  function scrollViewToPreselected(preSelectedCK: string): void {
    const preSelectedCountryDropRef = dropItemRefs.current.find(
      (d) => d.countryKey === preSelectedCK
    );

    if (preSelectedCountryDropRef && preSelectedCountryDropRef.element) {
      // If there's a timer, cancel it
      if (debouncer) {
        window.cancelAnimationFrame(debouncer);
      }

      // Setup the new requestAnimationFrame()
      debouncer = window.requestAnimationFrame(function () {
        preSelectedCountryDropRef?.element?.scrollIntoView({
          block: "center",
          inline: "nearest",
        });
      });
    }
  }

  // Handles keyboard country selection
  function handleKeyboardInput(event: React.KeyboardEvent<HTMLInputElement>) {
    const { key } = event;

    // If there's none of these keys, return
    if (!["ArrowDown", "ArrowUp", "Enter"].includes(key)) return;

    let currentIndex = filteredCountries.findIndex(
      (c) => c.key === preSelectedCountryKey
    );

    if (filteredCountries.length >= 1) {
      if (currentIndex === -1) {
        currentIndex = 0;
      }

      if (key === "ArrowDown") {
        currentIndex += 1;

        if (currentIndex >= filteredCountries.length)
          currentIndex = filteredCountries.length - 1;

        if (preSelectedCountryKey === NO_COUNTRY) {
          // Special case, I'm selecting the first country, it should be index 0
          // to select the first item

          currentIndex = 0;
        }
      }
      if (key === "ArrowUp") {
        currentIndex -= 1;
        if (currentIndex < 0) currentIndex = 0;
      }

      if (filteredCountries[currentIndex]?.key)
        setPreSelectedCountryKey(filteredCountries[currentIndex].key);

      scrollViewToPreselected(filteredCountries[currentIndex].key);
    }

    if (key === "Enter" && filteredCountries.length >= 1) {
      setSelectedCountry(filteredCountries[currentIndex]);
      setPreSelectedCountryKey(NO_COUNTRY);

      // When selected a same country again
      if (filteredCountries[currentIndex].key === selectedCountry?.key) {
        setFilterCountryName(selectedCountry?.text || "");
        setOpenDropView(false);
      }
    }
  }

  function onBlurCountryFilter(): void {
    setOpenDropView(false);

    // Checking if it is an invalid country
    const country = countries.find((elem) => elem.text === filterCountryName);

    // if (country) handleInvalidCountry(false);
    // else handleInvalidCountry(true);
  }

  // When typing, set the pre-selected country as the nearest one from fuzzy search
  // EXPERIMENTAL, adding fuzzy search to country
  useEffect(() => {
    const fuzzySearchCountryResult = fuse.search(filterCountryName);
    if (fuzzySearchCountryResult[0]) {
      setPreSelectedCountryKey(fuzzySearchCountryResult[0].item.key);
      scrollViewToPreselected(fuzzySearchCountryResult[0].item.key);
    }

    // if (filteredCountries[0])
    //   setPreSelectedCountryCode(filteredCountries[0].code);
  }, [filterCountryName, filteredCountries]);

  // When a country is selected, put it in the name
  useEffect(() => {
    setFilterCountryName(selectedCountry?.text || "");
    setOpenDropView(false);
    setCountryCode(selectedCountry?.code || "");
    // handleInvalidCountry(false);
    if (handleChangeCountry)
      handleChangeCountry(selectedCountry, { force: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry]);

  // Propagate country-code from input to this dropdown
  useEffect(() => {
    let country = countries.find((elem) => elem.code === countryCode);

    // If it's +1 we go with USA because USA > Canada
    if (countryCode === "+1") {
      country = countries.find((elem) => elem.key === "us");
    }

    if (country) {
      setSelectedCountry(country);
    }
  }, [countryCode]);

  return (
    <Container>
      <InputContainer key="STATIC" invalid={false}>
        <Flag
          key={selectedCountry?.key}
          src={
            selectedCountry
              ? `https://www.countryflags.io/${selectedCountry.key}/flat/64.png`
              : "https://lh3.googleusercontent.com/f3A57X3aC6sgllSbzBZWGNJsDRzMzRx0t0uiUbhpPODQUexnV8v18jbwIWRU95IvPZ130G3jDQxX5PZKvNdq-Hw=s400"
          }
        />
        <input
          key="STATIC"
          onFocus={() => {
            setOpenDropView(true);
          }}
          onBlur={onBlurCountryFilter}
          value={filterCountryName}
          onChange={(e) => {
            setFilterCountryName(e.target.value);
            setOpenDropView(true);
          }}
          onKeyDown={handleKeyboardInput}
        />
      </InputContainer>

      {openDropView && (
        <DropView showBorder={filteredCountries.length > 0}>
          {filteredCountries.map((country, index) => {
            return (
              <DropItem
                key={country.key}
                ref={(r) => {
                  dropItemRefs.current[index] = {
                    countryKey: country.key,
                    element: r,
                  };
                }}
                onMouseEnter={() => setPreSelectedCountryKey(country.key)}
                onMouseLeave={() => setPreSelectedCountryKey(NO_COUNTRY)}
                onMouseDown={(evt) => {
                  evt.stopPropagation();
                  setSelectedCountry(country);
                }}
                preSelected={preSelectedCountryKey === country.key}
              >
                <Flag
                  src={`https://www.countryflags.io/${country.key}/flat/64.png`}
                />
                <CountryName>{country.text}</CountryName>
              </DropItem>
            );
          })}
        </DropView>
      )}
    </Container>
  );
}
