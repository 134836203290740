import styled from "@emotion/styled";
import React from "react";

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 10px;
  border-radius: 5px;
  width: calc(100% - 10px);
  border: solid 1px
    ${(props: { invalid: boolean }) => (props.invalid ? "red" : "#cccccc")};
  background-color: #ffffff;

  & > input {
    padding: 15px;
    flex: 1;
    width: calc(100% - 45px);
    border: none;
    margin-left: 10px;
    font-size: 18px;
    &:focus {
      outline: none;
    }
  }
`;

export default InputContainer;
