/* eslint-disable no-underscore-dangle */
import React from "react";
import styled from "@emotion/styled";
import Button from "./StyledButton";
import StyledInput from "./StyledInput";
import SearchIcon from "../assets/icon/search.svg";
import LairCard from "./LairCard";
import { Flex } from "../utils/HelperStyles";
import { User } from "../utils/Types";
import { useModal } from "./Modal/Modal";
import CreateLair from "./Modal/container/CreateLair";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid;
  border-color: ${(props: any) =>
    props.color === "secondary"
      ? props.theme.colors.border
      : props.theme.colors.card.border};
  border-radius: 8px;
  background-color: ${(props: any) =>
    props.color === "secondary"
      ? props.theme.colors.background_aux
      : props.theme.colors.card.background};
`;

const Heading = styled.div`
  ${Flex};
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 30px 30px 0px;
`;

const Title = styled.span`
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.18px;
  color: ${(props) => props.theme.colors.text};
`;

const RightHeading = styled.div`
  display: flex;
  justify-content: right;
  flex-direction: row;
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 20px;
`;

interface Props {
  profileUser: User;
  color?: string;
}

export default function LairsList(props: Props): JSX.Element | null {
  const { profileUser, color } = props;
  const modal = useModal();

  return (
    <Container color={color}>
      <Heading>
        <Title>Lairs</Title>
        <RightHeading>
          <StyledInput
            style={{ width: "300px", height: "40px" }}
            placeholder="Search Lairs"
            IconSrc={SearchIcon}
            color={color}
          />
          <Button
            style={{ width: "136px", height: "40px", marginLeft: "20px" }}
            transparent
            onClick={() => {
              modal.pushModal(
                <CreateLair closeModal={modal.popModal} />,
                <Title>Create a lair</Title>
              );
            }}
          >
            Create Lair
          </Button>
        </RightHeading>
      </Heading>
      <Content>
        {profileUser.lairs.map(
          (lair) =>
            lair && <LairCard key={lair._id} lair={lair} color={color} />
        )}
      </Content>
    </Container>
  );
}

LairsList.defaultProps = {
  color: "primary",
};
