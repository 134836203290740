import React from "react";
import styled from "@emotion/styled";
import { useLocation } from "wouter";
import { useSetRecoilState } from "recoil";
import SettingIcon from "../../assets/icon/setting.svg";
import HelpIcon from "../../assets/icon/help.svg";
import AppearanceIcon from "../../assets/icon/appearance.svg";
import SignOutIcon from "../../assets/icon/signout.svg";
import { IconType, User } from "../../utils/Types";
import { BUCKET_URL } from "../../utils/Url";
import userInfoAtom from "../../atoms/UserInfoAtom";
import { Flex } from "../../utils/HelperStyles";
import getImageUrl from "../../utils/GetImageURL";

const Container = styled.div`
  z-index: 10;
  position: absolute;
  top: 40px;
  right: 0;
  width: 300px;
  height: 342px;
  background-color: ${(props) => props.theme.colors.background_aux};
  box-shadow: 0px 3px 8px #0000001f;
  border: 1px solid;
  border-color: ${(props) => props.theme.colors.border};
  border-radius: 4px;
`;

const Profile = styled.div`
  position: relative;
  display: flex;
  padding: 27px 0px 27px 30px;
  cursor: pointer;
`;

const ProfileName = styled.span`
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.18px;
  color: ${(props) => props.theme.colors.text};
  letter-spacing: 0px;
`;

const Text = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: 10px;
`;

const ProfileImage = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 48px;
  margin-left: 7px;
`;

const Subtext = styled.span`
  letter-spacing: 0.15px;
  color: #818a91;
  font-size: 15px;
`;

const Line = styled.div`
  ${Flex};
  display: flex;
  height: 44px;
  padding: 14px 30px;
  cursor: pointer;

  &:hover {
    background: #f4f9fc;
    color: #0075bb;
  }
`;

const Icon = styled.div`
  width: 15px;
  height: 15px;
  mask: url(${(props: IconType) => props.iconSVG}) no-repeat center;
  background-color: #b1bbc5;
`;

const Option = styled.span`
  margin-left: 8px;
  font-size: 15px;
  font-weight: medium;
  letter-spacing: 0.15px;
  color: ${(props) => props.theme.colors.text};
`;

interface Props {
  userInfo: User;
}

export default function ProfilePopover(props: Props): JSX.Element {
  const setUserInfo = useSetRecoilState(userInfoAtom);
  const [, setLocation] = useLocation();
  const { userInfo } = props;

  return (
    <Container>
      <Profile
        onClick={() => {
          setLocation(`/sl/${userInfo.username}`);
        }}
      >
        <ProfileImage alt="Profile Image" src={getImageUrl(userInfo.image)} />
        <Text>
          <ProfileName>{userInfo.name}</ProfileName>
          <Subtext>View your profile</Subtext>
        </Text>
      </Profile>

      <Line
        style={{ marginTop: "20px" }}
        onClick={() => {
          setLocation("/settings");
        }}
      >
        <Icon iconSVG={SettingIcon} />
        <Option>Settings</Option>
      </Line>

      <Line>
        <Icon iconSVG={HelpIcon} />
        <Option>Help Support</Option>
      </Line>

      <Line style={{ marginBottom: "20px" }}>
        <Icon iconSVG={AppearanceIcon} />
        <Option>Appearance</Option>
      </Line>

      <Line
        style={{ margin: "10px 0px" }}
        onClick={() => {
          localStorage.removeItem("@sociallair/uid");
          setUserInfo(null);
          setLocation("/");
        }}
      >
        <Icon iconSVG={SignOutIcon} />
        <Option>Sign out</Option>
      </Line>
    </Container>
  );
}
