/* eslint-disable no-underscore-dangle */
/** @jsxImportSource @emotion/react */
import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { useRecoilValue } from "recoil";
import { useModal } from "./Modal/Modal";
import CreatePost from "./Modal/container/CreatePost";
import MessageIcon from "../assets/icon/message.svg";
import { BUCKET_URL } from "../utils/Url";
import userInfoAtom from "../atoms/UserInfoAtom";
import { Flex } from "../utils/HelperStyles";
import lairAtom from "../atoms/LairAtom";
import getImageUrl from "../utils/GetImageURL";

const Wrapper = styled.div`
  ${Flex};
  flex-direction: column;
  height: fit-content;
  margin-bottom: 60px;
  padding: 40px 50px 50px 50px;
  cursor: default;
  width: 100%;
`;

const PostDiscussion: any = styled.div`
  display: flex;
  flex-direction: column;
  width: 595px;
  height: fit-content;
  background-color: ${(props: any) =>
    props.color === "primary"
      ? props.theme.colors.card.background
      : props.theme.colors.background_aux};
  border: 1px solid ${(props) => props.theme.colors.card.border};
  border-radius: 8px;
  margin-bottom: 50px;
`;

const TitleContainer = styled.div`
  height: 45px;
  ${Flex};
  padding: 12px;
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;
`;

const Title = styled.div`
  color: ${(props) => props.theme.colors.text};
  font-weight: 600;
  font-size: 15px;
  margin-left: 8px;
`;

const OpenModalPost = styled.div`
  color: #b1bbc6;
  font-size: 16px;
  margin-left: 12px;
`;

const CommentContainer = styled.div`
  height: 45px;
  ${Flex};
  border-top: 1px solid ${(props) => props.theme.colors.card.border};
  padding: 12px;
  cursor: pointer;
`;

const ProfileImage = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 30px;
`;

interface Props {
  page: string;
  onPost: any;
  color?: string;
}

export default function StartDiscussion(props: Props): JSX.Element {
  const { page, onPost, color } = props;
  const modal = useModal();
  const userInfo = useRecoilValue(userInfoAtom);
  const lair = useRecoilValue(lairAtom);

  // I left the wrapper instead of null so the white background doesn't appear if it's a dark theme
  if (!userInfo) return <Wrapper />;
  return (
    <PostDiscussion page={page} color={color}>
      <TitleContainer>
        <Icon src={MessageIcon} />
        <Title>Start a discussion</Title>
      </TitleContainer>
      <CommentContainer>
        <ProfileImage src={getImageUrl(userInfo.image)} />
        <OpenModalPost
          onClick={() => {
            modal.pushModal(
              <CreatePost
                closeModal={modal.popModal}
                onPost={onPost}
                userInfo={userInfo}
                lair={page === "home" ? null : lair?._id}
              />,
              <TitleContainer
                css={css`
                  height: auto;
                  padding: 0px;
                `}
              >
                <Icon src={MessageIcon} />
                <Title>Start a discussion</Title>
              </TitleContainer>
            );
          }}
        >
          What do you want to discuss, {userInfo.name}?
        </OpenModalPost>
      </CommentContainer>
    </PostDiscussion>
  );
}

StartDiscussion.defaultProps = {
  color: "primary",
};
