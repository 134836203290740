/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import MessageIcon from "../../assets/icon/header/message.svg";
import SearchIcon from "../../assets/icon/search.svg";
import MoreIcon from "../../assets/icon/more.svg";
import Input from "../StyledInput";
import Button from "../StyledButton";
import { Flex } from "../../utils/HelperStyles";

const Container = styled.div`
  padding-top: 5px;
  display: grid;
  grid-template-rows: 70px auto 90px;
`;

const Heading = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 40px auto;
  height: 70px;
  align-items: center;
  padding: 15px 25px;
  border-bottom: solid 1px ${(props) => props.theme.colors.card.border};
`;

const UserImage = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 40px;
`;

const UserName = styled.span`
  color: ${(props) => props.theme.colors.text};
  font-style: 18px;
  font-weight: 600;
  letter-spacing: 0.18px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlexDiv = styled.div`
  ${Flex};
  margin-left: 14px;
  flex-direction: row;
  justify-content: space-between;
`;

const Status = styled.span`
  letter-spacing: 0.15px;
  font-style: 15px;
  color: #818a91;
`;

const Icons = styled.div`
  display: flex;
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;
  margin: 0px 10px;
`;

const Content = styled.div``;

const SendMessage = styled.div`
  border-top: solid 1px ${(props) => props.theme.colors.card.border};
  display: grid;
  grid-template-columns: 72px auto 180px;
  align-items: center;
`;

export default function Messages(): JSX.Element {
  return (
    <Container>
      <Heading>
        <UserImage src="https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg" />
        <FlexDiv>
          <Column>
            <UserName>Gabriel Faria</UserName>
            <Status>Online</Status>
          </Column>
          <Icons>
            <Icon src={MessageIcon} /> <Icon src={MessageIcon} />{" "}
            <Icon src={MessageIcon} />
          </Icons>
        </FlexDiv>
      </Heading>

      <Content>Content</Content>
      <SendMessage>
        <FlexDiv>
          <UserImage
            src="https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg"
            css={css`
              width: 32px;
              height: 32px;
              border-radius: 32px;
              margin-right: 18px;
            `}
          />
        </FlexDiv>
        <Input
          style={{ height: "46px" }}
          placeholder="Write a comment…"
          IconSrc={MoreIcon}
        />
        <FlexDiv>
          <Button
            css={css`
              width: 140px;
              height: 46px;
            `}
            inverted
          >
            Send
          </Button>
        </FlexDiv>
      </SendMessage>
    </Container>
  );
}
