/* eslint-disable react/require-default-props */
import type { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import styled from "@emotion/styled";
import React from "react";

interface IContainer {
  disabledCss?: boolean;
  icon?: boolean;
  height?: string;
  width?: string;
}

const Container = styled.button<IContainer>`
  border: none;
  height: 48px;
  border-radius: 46px;
  width: ${(props) => props.width ?? "fit-content"};
  padding: 10px 50px;
  display: flex;
  flex-direction: row;
  height: ${(props) => props.height ?? "auto"};
  color: white;
  background-color: #0075bb;
  opacity: ${(props) => (props.disabledCss ? 0.5 : 1)};
  cursor: ${(props) => (props.disabledCss ? "default" : "pointer")};
  justify-content: ${(props) => (props.icon ? "space-between" : "center")};
  align-items: center;
  :focus {
    outline: none;
  }
`;

const IconContainer = styled.div`
  width: 15px;
  height: 15px;
  margin-right: 5px;
`;

const TextContainer = styled.div`
  font-size: 24px;
  width: auto;
  margin-left: 5px;
`;

interface IButton {
  icon?: ReactJSXElement;
  text: string;
  disabled?: boolean;
  height?: string;
  width?: string;
  onClick?: () => void;
}

export default function Button(props: IButton): JSX.Element {
  const { icon, text, disabled, onClick, height, width } = props;

  return (
    <Container
      onClick={onClick}
      disabledCss={disabled}
      disabled={disabled}
      icon={!!icon}
      width={width}
      height={height}
    >
      {icon && <IconContainer>{icon}</IconContainer>}
      <TextContainer>{text}</TextContainer>
    </Container>
  );
}
