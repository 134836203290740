/* eslint-disable no-underscore-dangle */
/** @jsxImportSource @emotion/react */
import React, { useRef, useState } from "react";
import styled from "@emotion/styled";
import { useRecoilState } from "recoil";
import { css, useTheme } from "@emotion/react";
import Button from "../../StyledButton";
import InputSetting from "../../InputSetting";
import { useModal } from "../Modal";
import ChangeImage from "./ChangeImage";
import { BUCKET_URL } from "../../../utils/Url";
import readFile from "../../../utils/ReadFile";
import { uploadImage } from "../../../api/Image";
import { createLair } from "../../../api/Lair";
import { Flex } from "../../../utils/HelperStyles";
import StyledSwitch from "../../StyledSwitch";
import userInfoAtom from "../../../atoms/UserInfoAtom";
import { getUserByUid } from "../../../api/User";

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.card.background};
  border-radius: 8px;
  height: min-content;
  display: flex;
  flex-direction: column;
  width: 620px;
`;

const Subtitle = styled.span`
  letter-spacing: 0.2px;
  color: ${(props) => props.theme.colors.text};
  font-size: 20px;
  font-weight: 600;
  margin: 37px 0px 0px 43px;
`;

const LairProfile = styled.div`
  display: grid;
  grid-template-areas:
    "image . "
    "image name "
    "image text "
    "image . ";
  grid-template-columns: 72px 200px;
  margin: 30px 75px 15px 67px;
  column-gap: 21px;
`;

const LairImage = styled.img`
  grid-area: image;
  width: 72px;
  height: 72px;
  border-radius: 72px;
`;

const LairName = styled.span`
  grid-area: name;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.18px;
  color: ${(props) => props.theme.colors.text};
  margin-bottom: 3px;
`;

const LairText = styled.span`
  grid-area: text;
  font-size: 16px;
  font-weight: medium;
  letter-spacing: 0.16px;
  color: #0075bb;
  margin-left: -2px;
  cursor: pointer;
`;

export const Subtext = styled.span`
  font-size: 15px;
  letter-spacing: 0.15px;
  color: #818a91;
  padding: 0px 0px 42px 160px;
`;

const SwitchDiv = styled.div`
  ${Flex};
  flex-direction: row;
  justify-content: flex-start;
  padding: 20px 26px 8px 28px;
`;

const SwitchLabel = styled.span`
  letter-spacing: 0.16px;
  font-size: 16px;
  font-weight: 600;
  text-align: right;
  width: 110px;
  color: ${(props) => props.theme.colors.text};
  margin-right: 22px;
`;

const ModalTitle = styled.span`
  color: ${(props) => props.theme.colors.text};
  font-weight: 600;
  font-size: 18px;
`;

export default function CreateLair(props: any): JSX.Element | null {
  const { closeModal } = props;
  const [userInfo, setUserInfo] = useRecoilState<any>(userInfoAtom);
  const modal = useModal();
  const theme = useTheme();
  const [disable, setDisable] = useState(false);
  const [approvePosts, setApprovePosts] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  const imageRef = useRef<HTMLInputElement>(null);
  const tagRef = useRef<HTMLInputElement>(null);
  // const lairNameRef = useRef<HTMLInputElement>(null);
  const [name, setName] = useState("");
  const descriptionRef = useRef<HTMLInputElement>(null);
  const locationRef = useRef<HTMLInputElement>(null);
  const [image, setImage] = useState<Blob | null>(null);
  const [isLoading, setLoading] = useState(false);

  const onFileChange = async (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const imageDataUrl: string = await readFile(file);

      modal.pushModal(
        <ChangeImage
          imageSrc={imageDataUrl}
          closeModal={() => modal.popModal()}
          saveImage={(croppedImage: Blob) => {
            setImage(croppedImage);
          }}
        />,
        <ModalTitle>Update Your Lair Photo</ModalTitle>
      );
    }
  };

  function checkInput(event: any) {
    if (event.target.value.length < 1) setDisable(true);
    else if (disable && event.target.value.length > 1) setDisable(false);
  }

  async function sendRequest(imageId: string) {
    const response = await createLair({
      image: imageId,
      name,
      admin: userInfo.uid,
      lairname: tagRef.current?.value,
      description: descriptionRef.current?.value,
      location: locationRef.current?.value,
      approvePosts,
      privacy,
    });
    if (response === 200) {
      setLoading(false);
      const user = await getUserByUid(userInfo.uid);
      setUserInfo(user);
      closeModal();
    } else if (response === 400) {
      setLoading(false);
      alert("Tag já existe");
    }
  }

  // id imagem padrao ZLm8kPo2wNkckdzh_KQ_G.png
  if (!userInfo) return null;
  return (
    <>
      <Container>
        <LairProfile>
          <LairImage
            src={
              image
                ? URL.createObjectURL(image)
                : `${BUCKET_URL}/file/ZLm8kPo2wNkckdzh_KQ_G.png`
            }
          />
          <LairName>{name}</LairName>
          <LairText onClick={() => imageRef.current?.click()}>
            <input
              ref={imageRef}
              type="file"
              accept="image/*"
              onChange={onFileChange}
              style={{ display: "none" }}
            />
            Change your lair picture
          </LairText>
        </LairProfile>
        <InputSetting
          onChange={(e) => {
            checkInput(e);
            setName(e.target.value);
          }}
          defaultValue=""
        >
          Lair name
        </InputSetting>

        <InputSetting ref={tagRef} onChange={checkInput} defaultValue="">
          Tagname
        </InputSetting>

        <InputSetting
          ref={descriptionRef}
          onChange={checkInput}
          defaultValue=""
        >
          Description
        </InputSetting>

        <InputSetting ref={locationRef} onChange={checkInput} defaultValue="">
          Location
        </InputSetting>
        <SwitchDiv>
          <SwitchLabel>Lair Privacy</SwitchLabel>
          <StyledSwitch
            toggled={privacy}
            whenToggled={(res) => {
              setPrivacy(res);
            }}
          />
        </SwitchDiv>

        <Subtext
          css={css`
            border-bottom: 1px solid;
            border-color: ${theme.colors.border};
          `}
        >
          Only members can see the activity and files shared in this lair.
        </Subtext>

        <Subtitle>Manage Lair Activity</Subtitle>

        <InputSetting onChange={checkInput} defaultValue="">
          Who Can Post
        </InputSetting>

        <SwitchDiv>
          <SwitchLabel>Approve Posts</SwitchLabel>
          <StyledSwitch
            toggled={approvePosts}
            whenToggled={(res) => {
              setApprovePosts(res);
            }}
          />
        </SwitchDiv>
        <Subtext>
          Posts by members must be approved by an admin or moderator.
        </Subtext>

        <Button
          inverted
          style={{ alignSelf: "flex-end", margin: "0px 43px 20px 0px" }}
          disable={disable}
          isLoading={isLoading}
          onClick={async () => {
            setLoading(true);
            if (image) {
              const response = await uploadImage(image);
              if (response.status === 200) {
                sendRequest(response.id);
              }
            } else {
              sendRequest("ZLm8kPo2wNkckdzh_KQ_G.png");
            }
          }}
        >
          Create
        </Button>
      </Container>
    </>
  );
}
