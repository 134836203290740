/* eslint-disable no-underscore-dangle */
import React, { useState } from "react";
import styled from "@emotion/styled";
import NoPendingPost from "../../assets/img/no-pending-post.svg";
import ManagePost from "./ManagePost";
import { Flex } from "../../utils/HelperStyles";
import { PostType } from "../../utils/Types";
import ImageCarousel from "../ImageCarousel";

const Container = styled.div`
  /* background-color: ${(props) => props.theme.colors.card.background}; */
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  width: 600px;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: ${(props) => props.theme.colors.text};
  letter-spacing: 0.18px;
  margin: 23px 0px;
`;

const FlexDiv = styled.div`
  ${Flex}
  height:80vh;
  justify-content: center;
`;

const Img = styled.img`
  width: 300px;
  height: 130px;
`;

interface Props {
  posts: PostType[];
  onChange: () => void;
}

export default function PendingPost(props: Props): JSX.Element | null {
  const { posts, onChange } = props;
  const [selectedPostImage, setSelectedPostImage] = useState<{
    post: PostType;
    imageIndex: number;
  } | null>(null);

  if (posts.length < 1)
    return (
      <FlexDiv>
        <Img src={NoPendingPost} />
      </FlexDiv>
    );
  return (
    <Container>
      <Title>Pending Post</Title>
      {selectedPostImage && (
        <ImageCarousel
          onClosePressed={() => setSelectedPostImage(null)}
          images={selectedPostImage.post.images || []}
          initialSlide={selectedPostImage.imageIndex || 0}
        />
      )}
      {posts.map((post: PostType) => (
        <ManagePost
          key={post._id}
          post={post}
          onChange={onChange}
          onImageClick={(imageIndex) =>
            setSelectedPostImage({ post, imageIndex })
          }
        />
      ))}
    </Container>
  );
}
