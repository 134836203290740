import React, { useRef, useState } from "react";
import styled from "@emotion/styled";
import { Link, useLocation } from "wouter";
import { useRecoilState, useSetRecoilState } from "recoil";
import Cropper from "react-avatar-editor";
import Button from "../StyledButton";
import ThreeDotsIcon from "../../assets/icon/threedots.svg";
import UnfriendIcon from "../../assets/icon/unfriend.svg";
import CellphoneIcon from "../../assets/icon/cellphone.svg";
import { IconType, User } from "../../utils/Types";
import useClickOutside from "../../hooks/useClickOutside";
import IconButton from "../IconButton";
import { BUCKET_URL } from "../../utils/Url";
import { connectionUser, getUserByUid, updateUser } from "../../api/User";
import userInfoAtom from "../../atoms/UserInfoAtom";
import { Flex } from "../../utils/HelperStyles";
import readFile from "../../utils/ReadFile";
import { uploadImage } from "../../api/Image";
import getImageUrl from "../../utils/GetImageURL";

const Container = styled.div`
  position: relative;
  ${Flex};
  justify-content: center;
  flex-direction: column;
`;

const CoverDiv = styled.div`
  width: 940px;
  height: 180px;
  border-radius: 8px 8px 0px 0px;
  margin-top: 30px;
  position: relative;
`;

const CoverGradient = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background: linear-gradient(to bottom, #000000de, #00000065, #00000000);
  border-radius: 8px 8px 0px 0px;
`;

const CoverImage = styled.img`
  width: 940px;
  height: 180px;
  border-radius: 8px 8px 0px 0px;
`;

const ImgDiv = styled.div`
  position: absolute;
  top: 100px;
  width: 132px;
  height: 132px;
  border-radius: 132px;
  background-color: ${(props) => props.theme.colors.background_aux};
  ${Flex};
  justify-content: center;
`;

const Img = styled.img`
  width: 126px;
  height: 126px;
  border-radius: 126px;
`;

const ProfileDiv = styled.div`
  width: 940px;
  background-color: ${(props) => props.theme.colors.background_aux};
  display: flex;
  flex-direction: column;
  padding-top: 35px;
  border-bottom: 1px solid;
  border-right: 1px solid;
  border-left: 1px solid;
  border-color: ${(props) => props.theme.colors.border};
  border-radius: 0px 0px 8px 8px;
`;

const Name = styled.span`
  text-align: center;
  font-size: 23px;
  font-weight: 600;
  letter-spacing: 0.23px;
  color: ${(props) => props.theme.colors.text};
`;

const Username = styled.span`
  text-align: center;
  font-size: 16px;
  letter-spacing: 0.16px;
  color: #818a91;
`;

const Description = styled.span`
  text-align: center;
  font-size: 15px;
  letter-spacing: 0.23px;
  color: ${(props) => props.theme.colors.text};
  padding: 12px 120px 0px 120px;
  margin-bottom: 10px;
`;

const Buttons = styled.div`
  top: 0;
  position: absolute;
  border-top: 1px solid;
  border-color: ${(props) => props.theme.colors.border};
  padding: 15px 24px;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const LeftButtons = styled.div`
  display: flex;
`;

const Information = styled.div`
  ${Flex};
  justify-content: center;
  flex-direction: column;
  padding: 0px 20px;
  border-left: 1.5px solid #38393a;
  cursor: pointer;
  color: #ffffff;

  &:hover {
    color: #0075bb;
  }

  &:nth-child(1) {
    padding-left: 0px;
    border-left: none;
  }
`;

const InformationNumber = styled.span`
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 0.2px;
`;

const InformationText = styled.span`
  font-size: 13px;
  letter-spacing: 0.13px;
`;

const RightButtons = styled.div`
  ${Flex};
`;

const ThreePoints = styled.div`
  position: relative;
`;

const Icon: any = styled.div`
  position: relative;
  mask: url(${(props: IconType) => props.iconSVG}) no-repeat center;
  width: 20px;
  height: 20px;
  background-color: #b1bbc5;

  &:hover {
    background-color: #0075bb;
  }
  cursor: pointer;
`;

const ModalDiv = styled.div`
  position: absolute;
  top: 33px;
  right: -16px;
  z-index: 10;
`;

const Modal = styled.div`
  width: 203px;
  background: ${(props) => props.theme.colors.background_aux};
  box-shadow: 0px 3px 8px #0000001f;
  border: 1px solid;
  border-color: ${(props) => props.theme.colors.border};
  position: relative;
  border-radius: 4px;

  &::after {
    content: "";
    position: absolute;
    right: 16px;
    top: -8px;
    box-sizing: border-box;
    border: 8px solid;
    border-color: ${(props) =>
      `transparent transparent ${props.theme.colors.background_aux} ${props.theme.colors.background_aux}`};
    transform: rotate(135deg);
    box-shadow: ${(props) => `-1px 1px 1px 0 ${props.theme.colors.border}`};
  }
`;

const CropperDiv = styled.div`
  position: absolute;
  width: 940px;
  height: 180px;
  border-radius: 8px 8px 0px 0px;
`;

interface Props {
  profileUser: User;
}

export default function Profile(props: Props): JSX.Element | null {
  // const setUserInfo = useSetRecoilState(userInfoAtom);
  const [userInfo, setUserInfo] = useRecoilState<any>(userInfoAtom);
  const [location, setLocation] = useLocation();
  const [modal, showModal] = useState(false);
  const [newImage, setNewImage] = useState("");
  const imageRef = useRef<HTMLInputElement>(null);
  const [image, setImage] = useState("");
  const editorRef = useRef<any>(null);

  const { profileUser } = props;

  // const dom = useClickOutside(() => {
  //   showModal(false);
  // });

  const onFileChange = async (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const imageDataUrl: any = await readFile(file);
      setNewImage(imageDataUrl);
    }
  };

  function getCroppedImage() {
    const croppedImage = editorRef.current.getImage().toDataURL();
    setImage(croppedImage);

    fetch(croppedImage)
      .then((res) => res.blob())
      .then(async (blob) => {
        const response = await uploadImage(blob);
        if (response.status === 200) {
          const success = await updateUser({
            uid: userInfo?.uid,
            coverImage: response.id,
          });
          if (success) {
            setUserInfo({ ...userInfo, coverImage: response.id });
          }
        }
      });

    setNewImage("");
  }

  if (!userInfo) return null;
  return (
    <Container>
      <CoverDiv>
        <CoverGradient />
        {newImage !== "" ? (
          <CropperDiv>
            <Cropper
              ref={editorRef}
              image={newImage}
              width={940}
              height={180}
              scale={1}
              border={0}
            />
            <button onClick={getCroppedImage} type="submit">
              save
            </button>
          </CropperDiv>
        ) : (
          <CoverImage
            alt=""
            src={
              image === ""
                ? `${BUCKET_URL}/file/${profileUser.coverImage}`
                : image
            }
          />
        )}
        <Buttons>
          <LeftButtons>
            <Link href={`/sl/${profileUser.username}`}>
              <Information
                style={
                  location.split(`/${profileUser.username}`)[1] === ""
                    ? { color: "#0075bb" }
                    : {}
                }
              >
                <InformationNumber>200</InformationNumber>
                <InformationText>Activity</InformationText>
              </Information>
            </Link>

            <Link href={`/sl/${profileUser.username}/lairs`}>
              <Information
                style={
                  location.split("/")[1] === "lairs" ? { color: "#0075bb" } : {}
                }
              >
                <InformationNumber>
                  {profileUser.lairs.length}
                </InformationNumber>
                <InformationText>Lairs</InformationText>
              </Information>
            </Link>

            <Link href={`/sl/${profileUser.username}/connections`}>
              <Information
                style={
                  location.split(`/${profileUser.username}/`)[1] ===
                  "connections"
                    ? { color: "#0075bb" }
                    : {}
                }
              >
                <InformationNumber>
                  {profileUser.connections.length}
                </InformationNumber>
                <InformationText>Connections</InformationText>
              </Information>
            </Link>
          </LeftButtons>
          <RightButtons>
            {profileUser.uid === userInfo.uid ? (
              <>
                <Button
                  transparent
                  onClick={() => setLocation("/settings")}
                  style={{ marginRight: "20px" }}
                >
                  Edit Profile
                </Button>
                <Button
                  transparent
                  onClick={() => imageRef.current?.click()}
                  style={{ width: "38px", height: "36px" }}
                />
                <input
                  ref={imageRef}
                  type="file"
                  accept="image/*"
                  onChange={onFileChange}
                  style={{ display: "none" }}
                />
              </>
            ) : (
              userInfo.connections.indexOf(profileUser.uid) === -1 && (
                <Button
                  transparent
                  style={{ marginRight: "20px" }}
                  onClick={async () => {
                    const response = await connectionUser(
                      userInfo.uid,
                      profileUser.uid
                    );

                    if (response) {
                      const testUser = await getUserByUid(userInfo.uid);
                      if (testUser) setUserInfo(testUser);
                    }
                  }}
                >
                  Connection
                </Button>
              )
            )}
          </RightButtons>
        </Buttons>
      </CoverDiv>

      <ImgDiv>
        <Img alt="" src={getImageUrl(profileUser.image)} />
      </ImgDiv>
      <ProfileDiv>
        <Name>{profileUser.name}</Name>
        <Username>{profileUser.username}</Username>
        <Description>{profileUser.description}</Description>

        {/* <FooterProfile>
          <Informations>
            <Link href={`/sl/${profileUser.username}`}>
              <Information
                style={
                  location.split(`/${profileUser.username}`)[1] === ""
                    ? { color: "#0075bb" }
                    : {}
                }
              >
                <InformationNumber>200</InformationNumber>
                <InformationText>Discussions</InformationText>
              </Information>
            </Link>

            <Link href={`/sl/${profileUser.username}/connections`}>
              <Information
                style={
                  location.split(`/${profileUser.username}/`)[1] ===
                  "connections"
                    ? { color: "#0075bb" }
                    : {}
                }
              >
                <InformationNumber>
                  {profileUser.connections.length}
                </InformationNumber>
                <InformationText>Connections</InformationText>
              </Information>
            </Link> */}

        {/* <Link href="/groups">
              <Information
                style={
                  location.split("/")[1] === "groups"
                    ? { color: "#0075bb" }
                    : {}
                }
              >
                <InformationNumber>13</InformationNumber>
                <InformationText>Groups</InformationText>
              </Information>
            </Link> */}

        {/* <Link href="/friends">
              <Information
                style={
                  location.split("/")[1] === "friends"
                    ? { color: "#0075bb" }
                    : {}
                }
              >
                <InformationNumber>222</InformationNumber>
                <InformationText>Friends</InformationText>
              </Information>
            </Link>

            <Link href="/followers">
              <Information
                style={
                  location.split("/")[1] === "followers"
                    ? { color: "#0075bb" }
                    : {}
                }
              >
                <InformationNumber>
                  {profileUser.followers.length}
                </InformationNumber>
                <InformationText>Followers</InformationText>
              </Information>
            </Link>

            <Link href="/following">
              <Information
                style={
                  location.split("/")[1] === "following"
                    ? { color: "#0075bb" }
                    : {}
                }
              >
                <InformationNumber>
                  {profileUser.following.length}
                </InformationNumber>
                <InformationText>Following</InformationText>
              </Information>
            </Link> */}
        {/* </Informations>
          <RightButtons>
            {profileUser.uid === userInfo.uid ? (
              <Button
                onClick={() => setLocation("/settings")}
                style={{ marginRight: "20px" }}
              >
                Edit Profile
              </Button>
            ) : (
              userInfo.connections.indexOf(profileUser.uid) === -1 && (
                <Button
                  style={{ marginRight: "20px" }}
                  onClick={async () => {
                    const response = await connectionUser(
                      userInfo.uid,
                      profileUser.uid
                    );

                    if (response) {
                      const testUser = await getUserByUid(userInfo.uid);
                      if (testUser) setUserInfo(testUser);
                    }
                  }}
                >
                  Connection
                </Button>
              )
            )} */}
        {/* <ThreePoints ref={dom}>
              <Icon
                onClick={() => {
                  showModal(!modal);
                }}
                iconSVG={ThreeDotsIcon}
              />
              {modal && (
                <ModalDiv>
                  <Modal>
                    <IconButton iconSVG={CellphoneIcon}>Unfollow</IconButton>
                    <IconButton iconSVG={UnfriendIcon} red>
                      Unfriend
                    </IconButton>
                  </Modal>
                </ModalDiv>
              )}
            </ThreePoints> */}
        {/* </RightButtons>
        </FooterProfile> */}
      </ProfileDiv>
    </Container>
  );
}
